import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Button, Typography, useTheme } from "@mui/material";
import log from "loglevel";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { DeviceContext } from "../../App";
import AuthenticationBottomMessages, { AuthenticationBottomMessage } from "../../authentication/components/AuthenticationBottomMessages";
import SigninOrGuestButtons from "../../authentication/components/SigninOrGuestButtons";
import { DeviceContextModel } from "../../config/useDeviceContext";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import LoyaltyContent from "../components/LoyaltyContent";
import OrderHeader from "../components/OrderHeader";
import { getLoyaltyLoginState, isThereALoyaltyOperation, LoyaltyLoginState } from "../helpers/LoyaltyHelpers";
import orderActions from "../redux/OrderActions";

interface LoyaltyPageProps { }

// TODO: handle "remise kebab", only cashback at the moment
const LoyaltyPage: React.FC<LoyaltyPageProps> = (props) => {

    const { selectedCatalog, selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const { user_authentication_state } = useTypedSelector((state: RootState) => state.authentication.data);
    const { order } = useTypedSelector((state: RootState) => state.order);
    const { estimatedLoyaltyConfigContent, estimatedLoyaltyOrderContent } = useTypedSelector((state: RootState) => state.loyalty);
    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { desktop_device } = React.useContext<DeviceContextModel>(DeviceContext);

    const [loyaltyLoginState, setLoyaltyLoginState] = useState<LoyaltyLoginState>();

    useEffect(() => {

        setLoyaltyLoginState(getLoyaltyLoginState(
            user_authentication_state,
            order.loyalty_user_id,
        ));

    }, [user_authentication_state, order]);

    const onActivateClicked = () => {

        log.debug(`Asking the API to compute loyalty for order ${order.id}`);
        dispatch(orderActions.earnLoyalty(order.id));
    }

    if (!selectedLocation || !selectedCatalog) {
        return null;
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            minHeight={1}
            data-test="loyalty_page.root"
        >
            <OrderHeader
                readOnly
                closeIcon={!desktop_device}
                titleId={intl.formatMessage({ id: "loyalty_page.title" })}
                backgroundColor={desktop_device ? theme.palette.background.paper : undefined}
            />

            <Box
                flexGrow={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{
                    padding: theme.spacing(4, 6),
                }}
                width={1}
            >

                {/* I */}
                {
                    (
                        loyaltyLoginState === LoyaltyLoginState.CONNECTED_NOT_MASTER
                        || (
                            loyaltyLoginState === LoyaltyLoginState.CONNECTED_MASTER
                            && !isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                        )
                    )
                    &&

                    <Box
                        display="flex"
                        justifyContent="center"
                        width={1}
                        padding={1.5}
                        style={{
                            border: "1px solid",
                            borderColor: theme.palette.grey[400],
                            borderRadius: "10px",
                        }}
                    >

                        <AccountCircleIcon
                            style={{
                                color: theme.palette.text.disabled,
                                opacity: .7,
                                height: "100%"
                            }}
                            fontSize="large"
                        />

                        <Box
                            marginLeft={2}
                            display="flex"
                            flexDirection="column"
                        >

                            <Typography variant="body1" color="textPrimary">
                                {user_authentication_state.user?.email}
                            </Typography>

                            <AuthenticationBottomMessages
                                messagesToDisplay={[AuthenticationBottomMessage.NOT_YOU_LOGOUT]}
                                customMargin={0}
                            />
                        </Box>
                    </Box>
                }

                <LoyaltyContent />

                <Box
                    display="flex"
                    flexDirection="column"
                    width={1}
                    marginTop={2}
                >

                    {/* K */}
                    {
                        loyaltyLoginState !== LoyaltyLoginState.ANONYMOUS
                        && !isThereALoyaltyOperation(order.loyalty_operations, estimatedLoyaltyConfigContent?.isPaymentsOnly)
                        && estimatedLoyaltyOrderContent
                        && estimatedLoyaltyOrderContent.howMuchEarned
                        &&

                        <Button
                            color="primary"
                            variant="contained"
                            style={{
                                textTransform: "none",
                                width: "100%",
                                padding: theme.spacing(2, 0),
                            }}
                            onClick={onActivateClicked}
                        >
                            <Typography>
                                {intl.formatMessage({ id: "orders.order_awaiting_payment.loyalty" })}
                            </Typography>
                        </Button>
                    }

                    {/* J */}
                    {loyaltyLoginState === LoyaltyLoginState.ANONYMOUS &&
                        <SigninOrGuestButtons
                            forceMandatory={true}
                        />
                    }
                </Box>
            </Box>
        </Box >
    );
}

export default LoyaltyPage;