import { DataFyreBrandProduct } from "../src/brands/models/DataFyreBrandProduct";
import { CatalogEntityType } from "../src/catalogs/models/CatalogEntity";
import { default as CatalogEntityChildLocation, default as CatalogLink } from "../src/catalogs/models/CatalogLink";
import CatalogReferentialScoring from "../src/catalogs/models/CatalogReferentialScoring";
import { Money } from "../src/common/models/Money";
import { Discount } from "../src/discounts/models/Discount";
import DiscountConfig from "../src/discounts/models/DiscountConfig";
import { PriceOverride } from "../src/products/models/PriceOverride";
import { ReferentialCategories } from "../src/referential/models/ReferentialCategories";
import { Restriction } from "../src/restrictions/model/Restriction";
import { UsageRestriction } from "../src/restrictions/model/UsageRestriction";
import { Suggestion } from "../src/suggestions/models/Suggestion";
import { Tax } from "../src/taxes/models/Tax";
import DealExt from "./DealExtended/DealExt";
import { CustomerInfo, RequireCustomerInfo } from "./Location";
import { ProductExtended } from "./ProductExtended/ProductExtended";

export const MIN_DEFAULT_PREPARATION_TIME = 20;
export const DEFAULT_TIMEZONE_NAME = "Europe/Paris";
export const DEFAULT_TIMEZONE: Timezone = { name: DEFAULT_TIMEZONE_NAME, utc_offset: 2 };
export const JERUSALEM_TIMEZONE_NAME = "Asia/Jerusalem";

export const DEFAULT_LOCALE = "fr-FR";
export const DEFAULT_LANGUAGE: string = DEFAULT_LOCALE.split("-")[0];

export const DEFAULT_COLLECTION_REQUIRE_CUSTOMER_INFO: CustomerInfo = {
    expected_time: true,
    first_name: true,
    email: true,
    phone: false,
    customer_notes: false
};
export const DEFAULT_REQUIRE_CUSTOMER_INFO: RequireCustomerInfo = {
    "collection": DEFAULT_COLLECTION_REQUIRE_CUSTOMER_INFO
}

export function getTimezoneName(dataWithTimezone?: { timezone: Timezone }): string {
    if (dataWithTimezone && dataWithTimezone.timezone && dataWithTimezone.timezone.name) {
        return dataWithTimezone.timezone.name;
    } else {
        console.warn(`No timezone defined, using default timezone: ${DEFAULT_TIMEZONE_NAME}`)
        return DEFAULT_TIMEZONE_NAME;
    }
}

/**
 * Catalog source. Mostly
 */

export enum CatalogSource {
    /**
     * Catalogs built from the parsing imported orders
     */
    POS = "pos",

    /**
     * Catalogs built from the parsing of receipts
     */
    RECEIPT = "receipt",

    /**
     * Default catalogs created and maintained by the restaurant owners
     */
    MANUAL = "manual",
}

export interface Catalog extends BaseCatalogEntity {
    id?: string,

    /**
     * Indicated the order of catalog in the list
     */
    order?: number;

    update_id?: string;

    parent_catalog?: CatalogEntityChildLocation;

    name: string

    /**
     * Used to termine the source of the catalog. 
     * Is it generated by the parsign receipts, imported from POS or created by the user
     */
    source?: CatalogSource

    timezone: Timezone,

    account_id?: string,

    location_id: string,

    ref: string,

    currency: string;

    /**
     * Moved here but the master is supposed to be the location
     */
    taxes?: Tax[];

    data: CatalogData,

    /**
     * 
     */
    discounts?: DiscountConfig,

    autotranslation: boolean,

    translation_status?: TranslationStatus,

    supported_languages: string[],

    language: string,

    restrictions?: Restriction[] | null,

    display?: CatalogDisplayConfiguration;

    referential_scoring?: CatalogReferentialScoring;

    created_at?: Date;

    updated_at?: Date;

    updated_by?: string;

    updated_with_ip?: string;

    auto_matched_at?: Date
}

/**
 * Catalog display configuration:
 * - Hide deal section
 * TODO: define template
 */
export interface CatalogDisplayConfiguration {
    /**
     * Hide deal desktop menu, useful if deals are in category
     */
    hide_deals_desktop_menu?: boolean;

    /**
     * Hide deal carousel for mobile, useful if deals are in category
     */
    hide_deals_mobile_carousel?: boolean;

    /**
     * Hide header
     */
    hide_header?: boolean;

    /**
     * Force display: grid, scroll
     */
    force_display?: CatalogDisplayMode
}

export enum TranslationStatus {

    IN_PROCESS = "in_process",

    DONE = "done",

    ERROR = "error",

}

export interface Timezone {

    name: string,

    utc_offset?: number

}

export interface CatalogData {

    categories: Categorie[];
    products: Product[];
    deals: Deal[];

    option_lists: OptionList[];
    options: Option[];

    suggestions?: Suggestion[];
    discounts?: Discount[];
}

export enum CategoryDisplayMode {
    /**
     * Display the product in grid mode
     */
    GRID = "grid",

    /**
     * Display the products in line mode
     */
    LINE = "line",

    /**
     * Display the subcategories 
     */
    SUBCATEGORIES = "subcategories",

    /**
     * Display the product without the image
     */

    WITHOUT_IMAGE = "without_image",
}

export enum CatalogDisplayMode {
    /**
    * Display the catalog in a full scroll
    */
    SCROLL = "scroll",

    /**
    * Display the catalog in grid mode
    */
    GRID = "grid",

    /**
     * Better display is chosen
     */
    AUTO = 'auto'

}

/**
 * Base interface to share properties among products, deals, etc.
 */
export interface BaseCatalogEntity {

    ref: string

    child_location?: CatalogLink;

    name: string

    description?: string;

    disable?: boolean

    display_if_disabled?: boolean

    disable_up_to_time?: Date | null

    entity_type?: CatalogEntityType;

    /**
     * Only use for display item of flatCatalog, 
     * for disable switch if parent is disable
     */
    isParentDisable?: boolean;
}

export interface Categorie extends BaseCatalogEntity {

    display?: CategoryDisplayMode

    icon?: string

    parent_ref?: string

    restrictions?: Restriction[] | Restriction | null

    /**
     * Default production level associated to all the products assigned to this category
     * Not implemented for now
     */
    default_production_level?: number;

    hasVat?: boolean;

    referential?: ReferentialCategories;
}

export interface Product extends BaseCatalogEntity {

    category_ref: string

    tax_ref?: string;

    skus: Sku[]

    image?: string

    tags?: Tags[]

    description_tags?: string[]

    best_seller?: boolean

    restrictions?: Restriction[] | Restriction | null

    allow_customer_notes?: boolean

    customs_fields?: {
        [key: string]: any
    }

    is_alcohol?: boolean

    // For products imported from product references
    referential?: DataFyreBrandProduct
}

export enum Tags {
    ALCOHOL = "alcohol",
    ALOCOHOL_FREE = "alcoholfree",
    DAIRY = "dairy",
    FISH = "fish",
    GLUTEN = "gluten",
    GLUTEN_FREE = "glutenfree",
    PEANUT = "peanut",
    PORK = "pork",
    SHELLFISH = "shellfish",
    SPICY = "spicy",
    TREENUTS = "treenuts",
    VEGETARIAN = "vegetarian",
    BIO = "bio",
    BEEF = "beef",
    HOMEMADE = "homemade",
    EGG = "egg",
    SUGAR = "sugar",
    VEGAN = "vegan"
}

export interface Sku extends BaseCatalogEntity {

    price: Money

    price_ref?: string;

    option_list_refs?: string[]

    suggestions_refs?: string[] // list de ref des Suggestions du sku

    product_ref?: string

    price_overrides?: PriceOverride[]

    tax_ref?: string;

    /**
     * Reference in the product brand referential
     */
    referential_ref?: string;

    /**
     * Used for the delivery, the weight has to be in grams
     */
    weight?: number;

    cost?: Money
}


export interface OptionList extends BaseCatalogEntity {

    /** 
     * @deprecated options are stored at the catalog level now and referenced by the new field option_lines.
     */
    options?: Option[];

    /**
     * Reference to the options at the catalog level.
     * // TODO: mark it as optional because it's definitely not available in pre-refactoring
     * catalogs. Marked it as mandatory at first to get all TS errors and know where to do the
     * refactoring.
     */
    option_lines: OptionLine[];

    type: "single" | "multiple"

    //Indique le min et le max d'option possible si multiple
    min?: number
    max?: number

    /**
     * If true, the same option can be selected several times
     */
    repeatable?: boolean;

    /**
     * If set, upt to this number can be selected without extra cost
     * Not implemented for now
     */
    max_before_extra?: number;

    // Used by connectors to know which type of option this is. For example with Lightspeed,
    // an option can be represented as an item or as a modifier.
    connector_type?: string;

    /**
     * Restriction for the option list
     */
    restrictions?: Restriction[] | Restriction | null
}

export interface OptionLine {

    option_ref: string;
    price: Money;

    /**
     * Price when the max number of free options is reached. Directly related to
     * OptionList.max_before_extra
    */
    extra_price?: Money;

    default?: boolean;

    entity_type?: CatalogEntityType;
    option_list_ref?: string;
}

export interface Option extends BaseCatalogEntity {

    /**
     * @deprecated now in OptionListOptionReference. Should be at the list level, not the option level.
     */
    price?: string;

    /**
     * @deprecated only used in API for Cashpad mapping. Old code.
     */
    price_ref?: string;

    /**
     * @deprecated only used for very specific Cashpad and Untill samples. Old code.
     */
    tax_ref?: string;

    /**
     * @deprecated now in OptionListOptionReference. Should be at the list level, not the option level.
     */
    default?: boolean

    customs_fields?: {
        [key: string]: any
    }

    /**
     * @deprecated
     */
    option_list_ref?: string

    /**
     * @deprecated now in OptionListOptionReference. Should be at the list level, not the option level.
     */
    extra_price?: Money;

    /**
     * @deprecated only used for very specific Cashpad and Untill samples. Old code.
     */
    price_overrides?: PriceOverride[];
}


export interface Deal extends BaseCatalogEntity {

    tags?: Tags[]

    description_tags?: string[]

    lines: DealLine[];

    restrictions?: Restriction[] | Restriction | null;
    usage_restriction?: UsageRestriction;

    category_ref?: string;

    category_id?: string;

    image?: string;

    customs_fields?: {
        [key: string]: any;
    }
}


export enum PricingEffect {
    EFFECT_UNCHANGED = "unchanged",
    EFFECT_FIXED_PRICE = "fixed_price",
    EFFECT_PRICE_OFF = "price_off",
    EFFECT_PERCENTAGE_OFF = "percentage_off"
}

export interface DealLine {

    /**
     * Index of the deal line in the deal
     * Filled in order pricing
     */
    index?: number;

    /**
     * DEPRECATED: called label in hubrise
     */
    name?: string
    label?: string
    // Optionnal in Hubrise model
    ref: string

    skus: DealSku[]

    pricing_effect: PricingEffect

    pricing_value?: Money | number

    /**
     * Hide the line
     * Only used when only one sku possible
     */
    hide?: boolean;

    /**
     * Default production level associated to all the products assigned to this category
     * Not implemented for now
     */
    default_production_level?: number;
}

export interface DealSku {

    ref: string

    extra_charge?: Money

}

export interface CategoriesAndProducts {

    data: Categorie | ProductExtended | DealExt

    type: CatalogEntityType

    mainCat: string // Item's main category ref

    subCat?: string // Item's sub category ref
}
