import { LogLevels } from "../../../model/Common/Log";
import { DeploymentName } from "../../common/models/DeploymentInfo";
import DeviceInfo from "./DeviceInfo";
import { MainRole } from "./MainRole";
import { ResetPasswordInfos } from "./ResetPasswordInfos";
import { TermsTarget } from "./Terms";
import { UserRole } from "./UserRole";

export enum SignInProviders {
    ANONYMOUS = "anonymous",
    PASSWORD = "password",
    GETRESTO = "getresto",
    GETRESTO_RESTAURANT = "getresto_restaurant",
    LYF = "lyf",
    CUSTOM = "custom"
}

export type AcceptedTermsDeployment = { [deployment in DeploymentName]?: Date };

export type AcceptedTerms = {
    [target in TermsTarget]?: AcceptedTermsDeployment;
};

export default interface BaseUser {
    uid?: string,
    private_ref?: string
    private_refs?: { [key in SignInProviders]?: string }
    email?: string,
    first_name?: string,
    last_name?: string,
    gender?: "male" | "female",
    // Birth date in ISO 8601 format for dates, ie YYYY-MM-DD.
    birth_date?: string,
    company_name?: string,
    //Phone number in E.164 format (*)
    phone?: string,
    address_1?: string,
    address_2?: string,
    postal_code?: string,
    city?: string,
    state?: string,
    // 	The two-letter country code as defined in ISO 3166-1 alpha-2.
    country?: string,

    /**
     * Currently selected browser locale
     */
    locale?: string;

    latitude?: string,
    longitude?: string,

    delivery_notes?: string | null,  // nullable so that we can remove it from the webapp (merge in OrderReducer)

    current_device?: DeviceInfo;
    devices?: { [key: string]: DeviceInfo };

    /**
     * @deprecated: now using roles in UserAccess objects (located in account or location)
     */
    roles?: Array<UserRole>;

    /**
     * User or admin role
     */
    user_type?: MainRole;

    /**
     * Should be set by decoding firebase token
     */
    email_verified?: boolean;

    /**
     * Should be set by decoding firebase token
     */
    phone_verified?: boolean;

    /**
     * The ID of the provider used to sign in the user.
     * One of `"anonymous"`, `"password"`, `"facebook.com"`, `"github.com"`,
     * `"google.com"`, `"twitter.com"`, `"apple.com"`, `"microsoft.com"`,
     * "yahoo.com"`, `"phone"`, `"playgames.google.com"`, `"gc.apple.com"`,
     * or `"custom"`.
     *
     * Additional Identity Platform provider IDs include `"linkedin.com"`,
     * OIDC and SAML identity providers prefixed with `"saml."` and `"oidc."`
     * respectively.
     */
    sign_in_provider?: SignInProviders;

    // If the user was anonymous and decided to login, keep a trace of their old IDs
    old_anonymous_ids?: string[];

    /**
     * Used to force the log level for a user
     */
    log_level?: LogLevels;

    /**
     * Terms = GTCU + privacy policy
     * @key target (ex: customer, professionals, partner). Usually differenciates
     *  the scope (webapp will be customers, backoffice will be professionals). See TermsTarget in Terms.ts.
     * @key deployment (ex: mylemonade, getresto, fyre). See DeploymentName in DeploymentInfo.ts.
     * @value application date of the accepted version.
     */
    last_accepted_terms?: AcceptedTerms;

    /**
     * @deprecated now using last_accepted_terms.
     */
    accept_terms?: boolean;

    /**
     * @deprecated now using last_accepted_terms
     */
    accept_privacy_policy?: boolean;

    reset_password_infos?: ResetPasswordInfos
}

/**
 * (*) The E.164 format must be used for any new implementation.
 * Be aware however that phone numbers retrieved from the API can be encoded in a different format.
 * The E.164 will become mandatory in a future release.
 */
