import { default as KRGlue } from "@lyracom/embedded-form-glue";
import { Box, useTheme } from "@mui/material";
import log from "loglevel";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { RedirectUrlFrom, RedirectUrlType } from "../../Common/models/RedirectUrlModels";
import { CommonActions } from "../../Common/redux/CommonActions";
import * as ROUTES from '../../config/routes';
import LyraPaymentInfos from "../../my-lemonade-library/model/payments/lyra/LyraPaymentIntentInfos";
import { orderService } from '../../my-lemonade-library/src/orders/services/OrderService';
import LyraBaseTransaction, { LyraTransactionDetailedStatus } from "../../my-lemonade-library/src/payments/models/lyra/LyraBaseTransaction";
import OrderHeader from "../../orders/components/OrderHeader";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import PaymentLyraGlobalStyle from "./PaymentLyraGlobalStyle";

interface PaymentLyraProps { }

const PaymentLyra: React.FC<PaymentLyraProps> = (props) => {

    const params = useParams<ROUTES.RouteParams>();
    const history = useHistory();
    const match: any = useRouteMatch();
    const dispatch = useDispatch();
    const intl = useIntl();
    const theme = useTheme();

    const [promiseError, setPromiseError] = useState<string | null>();

    const { sessionId } = useTypedSelector((state: RootState) => state.locations);

    const orderState = useTypedSelector((state: RootState) => state.order);
    const { order, payment_infos } = orderState;
    const lyraPaymentInfos = payment_infos as LyraPaymentInfos | undefined;

    useEffect(() => {
        const endpoint = "https://api.lyra.com";

        if (lyraPaymentInfos) {

            if (lyraPaymentInfos.form_token && lyraPaymentInfos.public_key) {
                const publicKey = lyraPaymentInfos.public_key;
                const formToken = lyraPaymentInfos.form_token;
                let successUrl = lyraPaymentInfos.order_payment.success_url ?? ROUTES.getOrderConfirmationFullRoute(
                    match.params.tableLinkKey,
                    order.id,
                    orderService.alreadySentNotPendingPayment(order) ? lyraPaymentInfos.order_payment.payment_intent_id : undefined,
                    sessionId,
                );
                const index = successUrl.search(`\/${match.params.tableLinkKey}`)
                successUrl = successUrl.substring(index)

                // TODO: set language
                KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
                    .then((data) =>
                        data.KR.setFormConfig({
                            /* set the minimal configuration */
                            formToken: formToken,
                            "kr-language": "fr-FR" /* to update initialization parameter */
                        })
                    )
                    .then((data) =>
                        data.KR.onSubmit((paymentData) => {
                            log.info(paymentData);
                            dispatch(CommonActions.setRedirectURL(
                                successUrl,
                                undefined,
                                RedirectUrlType.CONFIRM,
                                RedirectUrlFrom.PAYMENT_LYRA,
                            ));
                            return false; // Return false to prevent the redirection
                        })
                    )
                    .then((data) =>
                        data.KR.onError((paymentError) => {
                            log.warn(`Payment error for order ${order.id}: ${paymentError.errorCode} - ${paymentError.errorMessage}`);
                            if (paymentError.errorCode?.startsWith('ACQ')) {
                                // Check if the payment has been cancelled
                                const transactions: LyraBaseTransaction[] | undefined = paymentError.metadata?.answer?.clientAnswer.transactions;
                                const foundTransaction = transactions?.find((transaction) => transaction.metadata?.payment_intent_id === lyraPaymentInfos.order_payment.payment_intent_id);
                                if (foundTransaction?.detailedStatus === LyraTransactionDetailedStatus.CANCELLED) {
                                    log.debug(`Found cancelled/refunded transaction, redirect to confirmation page`);
                                    dispatch(CommonActions.setRedirectURL(
                                        successUrl,
                                        undefined,
                                        RedirectUrlType.CONFIRM,
                                        RedirectUrlFrom.PAYMENT_LYRA,
                                    ));
                                } else {
                                    // TODO: create a new transaction ?
                                }
                            }

                            return false; // Return false to prevent the redirection
                        })
                    )
                    .then((data) =>
                        // FormType is not exported
                        // @ts-ignore
                        data.KR.attachForm("#myPaymentForm")
                    ) /* add a payment form  to myPaymentForm div*/
                    .then((data) =>
                        data.KR.showForm(data.result.formId)
                    ) /* show the payment form */
                    .catch((error: any) =>
                        setPromiseError(error + " (see console for more details)")
                    );
            } else if (lyraPaymentInfos.payment_url && window.top) {
                window.top.location.href = lyraPaymentInfos.payment_url
            }
        } else {
            history.push(ROUTES.getCategoriesFullRoute(params.tableLinkKey))
        }
    }, [lyraPaymentInfos]);

    return (

        <Box height={1} style={{ backgroundColor: theme.palette.background.default, justifyContent: "flex-start" }}>

            <Box height={1} display="flex" flexDirection="column">

                <OrderHeader
                    titleId={intl.formatMessage({ id: "Payments.lyraform.title" })}
                    closeIcon
                    onlinePaymentMessage
                />
                <Box
                    p={3} display="flex"
                    flexDirection="column"
                    alignItems="center"
                    height={1}
                    justifyContent="center"
                    style={{ backgroundColor: theme.palette.background.paper }}
                >
                    <PaymentLyraGlobalStyle />
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height={1}>
                        <div id="myPaymentForm">
                            <div className="kr-smartform" kr-card-form-expanded=""></div>
                        </div>
                        <div>{promiseError}</div>
                    </Box>

                </Box>
            </Box>

        </Box >
    );
}

export default PaymentLyra;
