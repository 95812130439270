import CheckIcon from '@mui/icons-material/Check';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Product } from '../../my-lemonade-library/model/Catalog';
import { Order, OrderItemExtended, OrderItem as OrderItemType, OrderOption } from '../../my-lemonade-library/model/Order';
import { moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import PlusMinusQuantity from '../../orders/components/PlusMinusQuantity';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import PendingPaymentChip from './PendingPaymentChip';

type ShareItemProps = {
    order: Order;
    orderItem: OrderItemExtended;
    moreDetails: boolean;
    selectedItems?: OrderItemToPay[];
    remainingRatio?: number | null;
    onSelect: (val: OrderItemToPay[]) => void;
}

const ShareItem: React.FC<ShareItemProps> = (props) => {

    const {
        order,
        orderItem,
        moreDetails,
        selectedItems,
        remainingRatio,
        onSelect } = props

    const { selectedCatalog, selectedLocation } = useTypedSelector((state: RootState) => state.locations)

    const [productSelected, setProductSelected] = useState<Product>()
    const [quantity, setQuantity] = useState<number>(orderItem.quantity)
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [totalWithDifference, setTotalWithDifference] = useState<string | null>(null);

    const theme = useTheme();
    const intl = useIntl();

    useEffect(() => {

        if (orderItem && selectedCatalog) {

            const productList: Product[] = selectedCatalog.data.products;
            const product = productList.find(pdt => pdt.ref === orderItem.product_ref);

            if (product) {
                setProductSelected(product)
            }
            // If the product is not found in the catalog but the item
            // has already been sent (or is retrieved from a POS without catalog),
            // create a minimal product based on the item
            else if (orderItem.update_id) {

                const newProduct: Product = {
                    category_ref: "",
                    skus: [{
                        name: orderItem.product_name ?? orderItem.sku_name ?? "",
                        price: orderItem.price,
                        ref: orderItem.product_ref ?? orderItem.sku_ref,
                    }],
                    ref: orderItem.product_ref ?? orderItem.sku_ref,
                    name: orderItem.product_name ?? orderItem.sku_name ?? ""
                }

                setProductSelected(newProduct);
            }
        }
    }, [orderItem, selectedCatalog])

    useEffect(() => {
        if (selectedItems) {
            const index = selectedItems.findIndex(item => item.index === orderItem.index)
            if (index !== -1) {
                setIsChecked(true)
                if (selectedItems[index].quantity !== quantity) {
                    setQuantity(selectedItems[index].quantity)
                }
            } else {
                setIsChecked(false)
            }
        }
    }, [selectedItems]);




    const handleCheckbox = (item: OrderItemExtended) => {
        let itemsToPayArray: OrderItemToPay[]
        if (!isChecked) {
            itemsToPayArray = [{ index: item.index, quantity: item.quantity }]
        } else {
            itemsToPayArray = [{ index: item.index, quantity: 0 }]
        }
        onSelect(itemsToPayArray)
    }

    const getTotal = () => {
        if (selectedCatalog) {
            let price: number = moneyToNumber(orderItem.price)

            if (orderItem.options_price) {
                price += moneyToNumber(orderItem.options_price)
            }

            const total = price * (quantity)
            return numberToMoney(total, selectedCatalog.currency)
        }
        return ''
    }

    const getTotalWithDifference = () => {
        if (!remainingRatio) return null;
        const total: number = moneyToNumber(getTotal());
        return numberToMoney(total * remainingRatio, selectedCatalog?.currency || 'EUR');
    };

    const skuName = (orderItem: OrderItemType) => {
        if (productSelected) {
            const sku = productSelected.skus.find(sk => sk.ref === orderItem.sku_ref)
            return sku?.name
        } else {
            return ""
        }
    }

    const addItem = () => {
        if (selectedCatalog && selectedLocation) {
            const nQuantity = quantity + 1
            if (isChecked && onSelect) {
                const itemsToPayArray = [{ index: orderItem.index, quantity: nQuantity }]
                onSelect(itemsToPayArray)
            }
            setQuantity(nQuantity)
        }
    }

    const removeItem = () => {
        if (!selectedCatalog || !selectedLocation) return;
        const newQuantity = Math.max(0, quantity - 1);

        setQuantity(newQuantity);
        onSelect([{
            index: orderItem.index,
            quantity: newQuantity
        }]);

        if (newQuantity === 0) setIsChecked(false);
    };

    const getProductName = (): string => {

        const messageId: string = translationService.getProductNameTranslationKeyFromRef(orderItem.product_ref) ?? "";

        if (!intl.messages[messageId] && orderItem.update_id) {

            // No translation but update ID? -> ignore the error
            return orderItem.product_name ?? orderItem.sku_name;
        }

        return intl.formatMessage({
            id: messageId,
            defaultMessage: orderItem.product_name
        });
    }

    useMemo(() => {
        if (remainingRatio) {
            const total = getTotalWithDifference();
            setTotalWithDifference(total);
        }
    }, []);

    if (productSelected && orderItem) {
        return (

            <Box
                data-test={`select-item-${orderItem.index}`}
                width="100%"
                px={2}
                py={1}
                my={1}
                border="solid"
                style={{
                    borderWidth: "0em 0em .1em 0em",
                    borderColor: theme.palette.grey[100]
                }}
                bgcolor={isChecked
                    ? `${theme.palette.primary.main}26`
                    : theme.palette.background.default
                }
            >
                <Box
                    m={theme.spacing(0, 0, 2)}
                    data-test={`select-item-first-row-${orderItem.index}`}
                    onClick={() => handleCheckbox(orderItem)}
                >

                    <Box display="flex" flexDirection="row">
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            pr={3}
                        >

                            <Box
                                display="flex"
                                alignItems="center"
                                gap={2}
                            >
                                <Box display="flex">
                                    <Typography
                                        color="textPrimary"
                                        variant='subtitle1'
                                        style={{ marginRight: theme.spacing(1) }}
                                    >
                                        {`${orderItem.quantity}x`}
                                    </Typography>

                                    <Typography color="textPrimary">
                                        {getProductName()}
                                    </Typography>
                                </Box>

                                {paymentHelper.isItemPendingPayment(orderItem, order.payments) && <PendingPaymentChip />}
                            </Box>

                            <Box
                                lineHeight="1em"
                                ml={4}
                            >
                                {productSelected.skus.length > 1 &&
                                    <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                                        {skuName(orderItem)}
                                    </Typography>
                                }


                                <Box
                                    style={
                                        !moreDetails
                                            ? {
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 2,
                                            }
                                            : {}
                                    }
                                    data-test={`orderItem.options.ref_${orderItem.product_ref}`}
                                >
                                    {
                                        orderItem.options.map((elem: OrderOption, index: number) =>
                                        (<Typography key={index} variant="caption" style={{ color: theme.palette.text.disabled }}>
                                            {(index === 0 ? "" : " - ") + elem.name}
                                        </Typography>)
                                        )
                                    }
                                </Box>
                            </Box>
                        </Box>

                        <Stack
                            direction={'column'}
                            alignItems={'right'}
                        >
                            {totalWithDifference &&
                                <Typography color="textPrimary" variant='subtitle1'>
                                    {MoneyToStringWithSymbol(totalWithDifference)}
                                </Typography>
                            }
                            <Typography
                                color={totalWithDifference ? 'GrayText' : 'textPrimary'}
                                variant={totalWithDifference ? 'body2' : 'subtitle1'}
                                sx={{
                                    textDecorationLine: 'line-through'
                                }}
                            >
                                {MoneyToStringWithSymbol(getTotal())}
                            </Typography>
                        </Stack>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    alignContent='center'
                >


                    {orderItem.quantity > 1 &&
                        isChecked &&
                        <PlusMinusQuantity
                            value={quantity}
                            onAdd={() => addItem()}
                            onMinus={() => removeItem()}
                            min={0}
                            max={orderItem.quantity}
                        />
                    }

                    <Box
                        ml={4}
                        display="flex"
                        flex={1}
                        height={orderItem.quantity > 1 ? theme.spacing(4) : theme.spacing(3)}
                        onClick={() => handleCheckbox(orderItem)}
                    />

                    <CheckIcon
                        color={isChecked ? 'primary' : 'disabled'}
                        style={{ justifySelf: 'flex-end' }}
                        onClick={() => handleCheckbox(orderItem)}
                    />
                </Box>
            </Box >
        )
    }
    return null
}

export default ShareItem;