import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Checkbox, FormControlLabel, InputAdornment, OutlinedInput, Typography, useTheme } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Order } from '../../my-lemonade-library/model/Order';
import { DEFAULT_CURRENCY, Money, moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay';
import { PaymentAmountType } from '../../my-lemonade-library/src/payments/models/PaymentAmountType';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import PlusMinusQuantity from '../../orders/components/PlusMinusQuantity';
import { addLoyaltyDiscountToAmount } from '../../orders/helpers/OrderHelpers';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import { canDisplayItemsOnlyInSharePayment, getItemTotal, getManualPaymentAmounts } from '../helpers/PaymentHelpers';
import ShareDeal from './ShareDeal';
import ShareItem from './ShareItem';

/**
 * Component in charge to display Items of order
 * Able to define what type of item it is.
 */
interface SharePaymentSelectionProps {
    orderToDisplay?: Order | null;
    separatedItems: ReturnType<typeof paymentHelper.separatePaidAndRemainingItems>
    selectedItems?: OrderItemToPay[],
    amountRemaining: Money
    selectedShareType: PaymentAmountType
    customAmount: string
    splitBy: number | undefined
    userId: string;
    remainingRatio?: number;
    onItemQuantityChanged: (val: OrderItemToPay[]) => void;
    setSelectedItems: (items: OrderItemToPay[]) => void;  // Sets the state directly
    setSelectedShareType: (type: PaymentAmountType) => void
    handleCustomAmount: (val: string, isTotal?: boolean) => void
    handleSplitUpdate: (num: number) => void
}

const SharePaymentSelection: React.FC<SharePaymentSelectionProps> = (props) => {

    const {
        separatedItems,
        selectedItems,
        amountRemaining,
        selectedShareType,
        customAmount,
        splitBy,
        userId,
        remainingRatio,
        onItemQuantityChanged,
        setSelectedItems,
        setSelectedShareType,
        handleCustomAmount,
        handleSplitUpdate,
    } = props;

    const { order } = useTypedSelector((state: RootState) => state.order)
    const { selectedCatalog } = useTypedSelector((state: RootState) => state.locations)
    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations)

    const [moreDetails, setMoreDetails] = useState<boolean>(false);

    const intl = useIntl();
    const theme = useTheme();

    const displayItemsOnly = useMemo(() => {
        return canDisplayItemsOnlyInSharePayment(
            order,
            selectedCatalog?.currency ?? DEFAULT_CURRENCY,
            Boolean(selectedLocation?.share_payment_config?.item_selection_only),
        )
    }, [order, selectedCatalog?.currency, selectedLocation?.share_payment_config?.item_selection_only]);

    useEffect(() => {
        if (displayItemsOnly) {
            setSelectedShareType(PaymentAmountType.ITEMS);
        }
    }, [displayItemsOnly]);

    const manualPaymentAmounts = useMemo(() => getManualPaymentAmounts(order.payments), [order.payments]);

    const displayPaidSection = useMemo(() => {
        return Boolean(
            Object.keys(separatedItems.alreadyPaidDealItems).length > 0
            || separatedItems.alreadyPaidItems.length > 0
            || manualPaymentAmounts.length > 0
        );
    }, [manualPaymentAmounts.length, separatedItems.alreadyPaidDealItems, separatedItems.alreadyPaidItems.length]);

    const getDealPrice = (key: string): Money => {
        return order.deals[key].price_with_options ?? order.deals[key].price
    }

    const renderTitle = (id: string): JSX.Element => {
        return (
            <Box
                width={1}
                px={3}
                py={2}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
            >
                <Typography variant='h5' color="textSecondary">
                    {intl.formatMessage({ id })}
                </Typography>

                {id === 'orders.sharePayment.remaining' &&
                    <Typography variant='h5' color="textSecondary">
                        {MoneyToStringWithSymbol(amountRemaining)}
                    </Typography>
                }
            </Box >
        )
    }

    const getSplitAmount = (): string => {

        if (!splitBy) {
            return "";
        }

        const currency = selectedCatalog?.currency ?? DEFAULT_CURRENCY;

        // TODO: use lodash
        const finalAmount = Math.round(moneyToNumber(
            // Split using the "real" total, withtout the loyalty discount (which will be applied on the current user part later)
            addLoyaltyDiscountToAmount(amountRemaining, order.discounts, userId, currency)
        ) / splitBy * 100) / 100

        return MoneyToStringWithSymbol(numberToMoney(finalAmount, currency));
    }

    const handleSharePaymentTypeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {

        const shareType = (event.target as HTMLInputElement).value;
        setSelectedShareType(shareType as PaymentAmountType);

        // Pay all while in items only means pay all items
        if (shareType === PaymentAmountType.FULL && displayItemsOnly) {
            const itemsToPay: OrderItemToPay[] = [];
            separatedItems.remainingItems.forEach((item) => {
                itemsToPay.push(item)
            });
            Object.values(separatedItems.remainingDealItems).forEach((dealItems) => {
                itemsToPay.push(...dealItems);
            });
            setSelectedItems(itemsToPay);
        }
    }

    return (

        <Box
            display="flex"
            overflow="auto"
            flexDirection="column"
            alignItems="center"
            minHeight="100px"
            style={{ backgroundColor: theme.palette.background.paper }}
        >

            {renderTitle('orders.sharePayment.remaining')}

            {/* Remaining amount */}
            <Box
                alignSelf='start'
                px={3}
                border="solid"
                width={1}
                style={{
                    borderWidth: "0em 0em .1em 0em",
                    borderColor: theme.palette.grey[100]
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            data-test="orderList_allSelected_button"
                            icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                            checkedIcon={<CheckCircleIcon color='primary' />}
                            value={PaymentAmountType.FULL}
                            checked={selectedShareType === PaymentAmountType.FULL}
                            onChange={handleSharePaymentTypeSelection}
                        />}
                    label={
                        <Typography >
                            {intl.formatMessage({ id: 'orders.sharePayment.pay_remaining' })}
                        </Typography>
                    }
                />
            </Box>

            {/* Manual Amount */}
            {!displayItemsOnly &&

                <Box
                    alignSelf='start'
                    display='flex'
                    flexDirection='column'
                    px={3}
                    py={1}
                    border="solid"
                    width={1}
                    style={{
                        borderWidth: "0em 0em .1em 0em",
                        borderColor: theme.palette.grey[100]
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-test="orderList_custom_amount_button"
                                icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                checkedIcon={<CheckCircleIcon color='primary' />}
                                value={PaymentAmountType.MANUAL}
                                checked={selectedShareType === PaymentAmountType.MANUAL}
                                onChange={handleSharePaymentTypeSelection}
                                style={{ height: 20 }}
                            />}
                        label={
                            <Typography >
                                {intl.formatMessage({ id: 'orders.sharePayment.custom_amount' })}
                            </Typography>
                        }
                    />

                    <Box pl={4}>
                        <Typography variant='caption' style={{ color: theme.palette.text.disabled }} >
                            {intl.formatMessage({ id: 'orders.sharePayment.custom_amount.desc' })}
                        </Typography>

                        {selectedShareType === PaymentAmountType.MANUAL &&
                            <OutlinedInput
                                placeholder='0,00'
                                endAdornment={<InputAdornment position="end">
                                    {getSymbolFromCurrency(
                                        selectedCatalog
                                            ? selectedCatalog.currency
                                            : DEFAULT_CURRENCY
                                    )}
                                </InputAdornment>}
                                value={customAmount}
                                onChange={(e) => handleCustomAmount(e.target.value)}
                                onBlur={(e) => handleCustomAmount(e.target.value, true)}
                                style={{
                                    margin: theme.spacing(1),
                                    width: theme.spacing(12),
                                    height: theme.spacing(4)
                                }}
                                inputProps={{
                                    "data-test": "payment_sharePayment_freeAmount_input",
                                    style: {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }
                                }}
                            />
                        }

                    </Box>
                </Box>
            }

            {/* Split by */}
            {(splitBy && !displayItemsOnly) &&

                <Box
                    alignSelf='start'
                    display='flex'
                    flexDirection='column'
                    px={3}
                    py={1}
                    border="solid"
                    width={1}
                    style={{
                        borderWidth: "0em 0em .1em 0em",
                        borderColor: theme.palette.grey[100]
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-test="orderList_split_button"
                                icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                checkedIcon={<CheckCircleIcon color='primary' />}
                                value={PaymentAmountType.SPLIT}
                                checked={selectedShareType === PaymentAmountType.SPLIT}
                                onChange={handleSharePaymentTypeSelection}
                                style={{ height: 20 }}
                            />}
                        label={
                            <Typography >
                                {intl.formatMessage({ id: 'orders.sharePayment.splitBill' })}
                            </Typography>
                        }
                    />

                    <Box pl={4}>
                        <Typography variant='caption' style={{ color: theme.palette.text.disabled }}>
                            {intl.formatMessage({ id: 'orders.sharePayment.splitBillNote' })}
                        </Typography>

                        {selectedShareType === PaymentAmountType.SPLIT &&
                            <Box
                                mt={1}
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <PlusMinusQuantity
                                    value={splitBy}
                                    onAdd={() => handleSplitUpdate(1)}
                                    onMinus={() => handleSplitUpdate(-1)}
                                    min={1}
                                    optionnalText={"orders.sharePayment.divideBy"}
                                />

                                {selectedCatalog &&
                                    <Box display='flex'>
                                        <Typography color='primary'>
                                            {getSplitAmount()}
                                        </Typography>
                                        <Typography>
                                            {intl.formatMessage({ id: 'orders.sharePayment.perPerson' })}
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                </Box>

            }

            {/* Select Items */
                <Box
                    alignSelf='start'
                    display='flex'
                    flexDirection='column'
                    px={3}
                    py={1}
                    border="solid"
                    width={1}
                    style={{
                        borderWidth: "0em 0em .1em 0em",
                        borderColor: theme.palette.grey[100]
                    }}
                >

                    <Box
                        display='flex'
                        justifyContent='space-between'
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-test="orderList_select_items_button"
                                    icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                    checkedIcon={<CheckCircleIcon color='primary' />}
                                    value={PaymentAmountType.ITEMS}
                                    checked={selectedShareType === PaymentAmountType.ITEMS}
                                    onChange={handleSharePaymentTypeSelection}
                                    style={{ height: 20 }}
                                />}
                            label={
                                <Typography >
                                    {intl.formatMessage({ id: 'orders.sharePayment.choose_items' })}
                                </Typography>
                            }
                        />
                        <Box
                            onClick={() => setMoreDetails(!moreDetails)}
                            style={{ cursor: 'pointer' }}
                        >
                            <Typography
                                variant='caption'
                                color='primary'
                            >
                                {moreDetails
                                    ? intl.formatMessage({ id: 'Summary.lessDetails' })
                                    : intl.formatMessage({ id: 'Summary.moreDetails' })
                                }
                            </Typography>
                        </Box>

                    </Box>

                    <Box>

                        <Box pl={4}>
                            <Typography variant='caption' style={{ color: theme.palette.text.disabled }} >
                                {intl.formatMessage({ id: 'orders.sharePayment.choose_items_caption' })}
                            </Typography>
                        </Box>

                        {selectedShareType === PaymentAmountType.ITEMS &&
                            <Box>
                                { // NEW PRODUCTS
                                    separatedItems.remainingItems.map((orderItem, index) =>
                                        <ShareItem
                                            key={`new-products-${index}`}
                                            order={order}
                                            orderItem={orderItem}
                                            remainingRatio={remainingRatio}
                                            moreDetails={moreDetails}
                                            selectedItems={selectedItems}
                                            onSelect={onItemQuantityChanged}
                                        />
                                    )
                                }

                                {// NEW DEALS
                                    Object.entries(separatedItems.remainingDealItems).map(([dealKey, dealItems], index) => (
                                        <ShareDeal
                                            key={`new-deals-${index}`}
                                            order={order}
                                            dealKey={dealKey}
                                            dealItems={dealItems}
                                            moreDetails={moreDetails}
                                            selectedItems={selectedItems}
                                            onSelect={onItemQuantityChanged}
                                        />
                                    ))
                                }
                            </Box>

                        }

                    </Box>
                </Box>
            }

            {displayPaidSection &&
                <Box width={1}>
                    <Box
                        width={1}
                        height={theme.spacing(2)}
                        bgcolor={theme.palette.background.default}
                    />
                    {renderTitle('orders.sharePayment.paid')}

                    { // PAID PRODUCTS
                        separatedItems.alreadyPaidItems.map((orderItem, index) => (
                            orderItem.subtotal
                                ? <Box
                                    key={`paid-items-${index}`}
                                    px={3}
                                    py={1}
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    color={theme.palette.text.disabled}
                                    border="solid"
                                    width={1}
                                    style={{
                                        borderWidth: "0em 0em .1em 0em",
                                        borderColor: theme.palette.grey[100]
                                    }}
                                >
                                    <Typography>
                                        {`${orderItem.quantity}x ${orderItem.product_name}`}
                                    </Typography>
                                    <Typography>
                                        {MoneyToStringWithSymbol(getItemTotal(order, orderItem, selectedCatalog?.currency ?? DEFAULT_CURRENCY))}
                                    </Typography>
                                </Box>
                                : null
                        ))
                    }

                    { // PAID DEALS
                        Object.entries(separatedItems.alreadyPaidDealItems).map(([dealKey, dealItems], index) => (

                            <Box
                                key={`paid-deals-${index}`}
                                px={3}
                                py={1}
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                                color={theme.palette.text.disabled}
                                border="solid"
                                width={1}
                                style={{
                                    borderWidth: "0em 0em .1em 0em",
                                    borderColor: theme.palette.grey[100]
                                }}
                            >
                                <Typography>
                                    {`1x ${order.deals[dealKey]?.name}`}
                                </Typography>
                                <Typography>
                                    {MoneyToStringWithSymbol(getDealPrice(dealKey))}
                                </Typography>
                            </Box>
                        ))
                    }

                    {// Manual Amount
                        manualPaymentAmounts.length > 0 &&
                        manualPaymentAmounts.map((amount, index) => (
                            <Box
                                key={`manual-amount-${index}`}
                                width={1}
                                pl={3}
                                pr={3}
                                py={2}
                                display='flex'
                                justifyContent='space-between'
                                border="solid"
                                color={theme.palette.text.disabled}
                                style={{
                                    borderWidth: "0em 0em .1em 0em",
                                    borderColor: theme.palette.grey[100]
                                }}
                            >
                                <Typography align='left'>
                                    {intl.formatMessage({ id: 'orders.sharePayment.custom_amount' })}
                                </Typography>

                                <Typography>
                                    {MoneyToStringWithSymbol(amount)}
                                </Typography>
                            </Box>
                        ))
                    }
                </Box>
            }
        </Box>
    )
}

export default SharePaymentSelection
