import { Box, useTheme } from "@mui/material"
import _ from "lodash"
import log from "loglevel"
import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { OrderSummaryPage } from "../../config/routes"
import { Catalog } from "../../my-lemonade-library/model/Catalog"
import { SupportedPayementType } from "../../my-lemonade-library/model/Location"
import { OrderCharge, OrderChargeType, PaymentType } from "../../my-lemonade-library/model/Order"
import { addMoney, DEFAULT_CURRENCY, Money, moneyToNumber, numberToMoney } from "../../my-lemonade-library/src/common/models/Money"
import { AutoId } from "../../my-lemonade-library/src/common/services/AutoId"
import discountService from "../../my-lemonade-library/src/discounts/service/DiscountService"
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay'
import { PaymentAmountType } from "../../my-lemonade-library/src/payments/models/PaymentAmountType"
import { PaymentShareInfos } from "../../my-lemonade-library/src/payments/models/PaymentShareInfos"
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper'
import OrderHeader from "../../orders/components/OrderHeader"
import { isLocalLoyaltyOnly } from "../../orders/helpers/LoyaltyHelpers"
import { addLoyaltyDiscountToAmount, removeLoyaltyDiscountFromAmount } from "../../orders/helpers/OrderHelpers"
import { sendOrPayOrder } from "../../orders/services/OrderService"
import { RootState, useTypedSelector } from "../../redux/root-reducer"
import ShareItemsPageBottom from "../components/ShareItemsPageBottom"
import SharePaymentSelection from "../components/SharePaymentSelection"
import TipModal from "../components/TipModal"
import { getItemTotal } from "../helpers/PaymentHelpers"
import useTipConfiguration from "../hooks/useTipConfiguration"


const SharePaymentPage = () => {

    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const { selectedCatalog, selectedLocation, tableLinkId } = useTypedSelector((state: RootState) => state.locations);
    const orderState = useTypedSelector((state: RootState) => state.order);
    const { order } = orderState;
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const { use_points: localLoyaltyUsePoints, estimatedLoyaltyOrderContent } = useTypedSelector((state: RootState) => state.loyalty);

    const { tip, askForTip } = useTipConfiguration(orderState.payment_type);

    const currency = selectedLocation?.currency ?? selectedCatalog?.currency ?? DEFAULT_CURRENCY;
    const userId = data.user_authentication_state.user?.uid ?? "";

    const [selectedShareType, setSelectedShareType] = useState<PaymentAmountType>(PaymentAmountType.FULL);
    const [finalAmountToPay, setFinalAmountToPay] = useState<Money>(numberToMoney(0, currency));
    const [errorMessage, setErrorMessage] = useState<(string | null)>(null);
    const [minAmount, setMinAmount] = useState<Money>();
    const [maxAmount, setMaxAmount] = useState<Money>();

    // Items selection
    const [selectedItems, setSelectedItems] = useState<OrderItemToPay[]>([]);

    // Custom amount
    const [customAmount, setCustomAmount] = useState<string>('');

    // Split by
    const [splitBy, setSplitBy] = useState<number>();

    // Tips
    const [openTipModal, setOpenTipModal] = useState<boolean>(false);
    const [tipCharge, setTipCharge] = useState<OrderCharge>();

    const DEFAULT_SPLIT_BY = 2;

    /**
     * // TODO: why here? Why in a useEffect? should be in tipModal
     */
    useEffect(() => {
        if (!tipCharge && selectedCatalog) {
            const newCharge: OrderCharge = {
                id: AutoId.newId("", 28),
                type: OrderChargeType.TIP,
                contributor_id: userId,
                name: OrderChargeType.TIP,
                price: numberToMoney(0, currency),
            }
            setTipCharge(newCharge)
        }
    }, [tipCharge, currency, selectedCatalog, userId]);

    const separatedItems = useMemo(() => paymentHelper.separatePaidAndRemainingItems(order), [order]);

    /**
     * // TODO: why loyalty discount?
     */
    const amountRemaining = useMemo(() => {
        const amountNumber = paymentHelper.getOrderRemainingAmount(order);
        // TODO: what about several users?
        return addLoyaltyDiscountToAmount(
            numberToMoney(amountNumber, currency),
            order.discounts,
            userId,
            currency
        );
    }, [currency, order, userId]);

    /**
     * Refresh the min and max amount, based on the payment type and latest orderPayments
     */
    useEffect(() => {

        const paymentTypeInfo: SupportedPayementType | undefined = selectedLocation?.supported_payment_types?.find(payment => (
            payment.type === orderState.payment_type
        ))

        if (paymentTypeInfo) {

            const paymentShareInfos: PaymentShareInfos | undefined = paymentHelper.getSharePaymentsInfos(
                order.payments,
                paymentTypeInfo,
                currency,
            )

            if (paymentShareInfos) {

                if (paymentShareInfos.share_number) {
                    setSplitBy(paymentShareInfos.share_number)
                }

                if (paymentShareInfos.min_amount) {
                    setMinAmount(paymentShareInfos.min_amount)
                }

                if (paymentShareInfos.max_amount) {
                    setMaxAmount(paymentShareInfos.max_amount)
                }
            }
        }
    }, [order.payments, selectedLocation?.supported_payment_types, currency, orderState.payment_type])

    const fullAmount = useMemo(() => {
        const amountNumber = paymentHelper.getOrderRemainingAmount(order);

        if (isLocalLoyaltyOnly(order, selectedLocation?.enable_share_payment)) {
            return removeLoyaltyDiscountFromAmount(
                numberToMoney(amountNumber, currency),
                order,
                userId,
                currency,
                selectedLocation?.enable_share_payment,
                localLoyaltyUsePoints,
                estimatedLoyaltyOrderContent?.priceOff ?? undefined,
            );
        }

        return numberToMoney(amountNumber, currency);
    }, [
        order,
        selectedLocation?.enable_share_payment,
        currency,
        userId,
        localLoyaltyUsePoints,
        estimatedLoyaltyOrderContent?.priceOff
    ]);

    const remainingRatio = useMemo(() => {
        const isSharePayment = order && order?.payments?.every((p) => p.payment_type !== PaymentType.TABLE) && moneyToNumber(amountRemaining) !== 0;

        if (isSharePayment && selectedShareType === PaymentAmountType.ITEMS) return ((100 * moneyToNumber(amountRemaining)) / moneyToNumber(order.total)) / 100;
    }, [selectedShareType])

    const isSharePayment = useMemo(() => {
        return order && order?.payments?.every((p) => p.payment_type !== PaymentType.TABLE) && moneyToNumber(amountRemaining) !== 0;
    }, [order, amountRemaining]);

    const partialAmount = useMemo(() => {
        let nTotal = 0;

        switch (selectedShareType) {
            case PaymentAmountType.ITEMS:
                if (selectedItems.length > 0) {
                    nTotal = selectedItems.reduce((total, item) => {
                        return total + moneyToNumber(getItemTotal(order, item, currency));
                    }, 0);

                    if (isSharePayment && remainingRatio) nTotal = nTotal * remainingRatio;

                    nTotal = discountService.getOrderAmountMinusDiscountTotal(nTotal, selectedCatalog as Catalog, currency, order.discounts);
                }
                break;

            case PaymentAmountType.SPLIT:
                if (splitBy) {
                    nTotal = _.round(
                        moneyToNumber(addLoyaltyDiscountToAmount(amountRemaining, order.discounts, userId, currency)) / splitBy,
                        2
                    );
                }
                break;

            case PaymentAmountType.MANUAL:
                if (customAmount !== '') {
                    nTotal = parseFloat(customAmount);
                }
                break;
        }

        return removeLoyaltyDiscountFromAmount(
            numberToMoney(nTotal, currency),
            order,
            userId,
            currency,
            selectedLocation?.enable_share_payment,
            localLoyaltyUsePoints,
            estimatedLoyaltyOrderContent?.priceOff ?? undefined,
        );
    }, [
        selectedShareType,
        selectedItems,
        order,
        currency,
        selectedCatalog,
        splitBy,
        customAmount,
        userId,
        selectedLocation?.enable_share_payment,
        localLoyaltyUsePoints,
        estimatedLoyaltyOrderContent?.priceOff
    ]);

    /**
    * Main recomputation of the final amount to pay when
    * anything changes (share type, splitBy, etc)
    */
    useEffect(() => {
        const finalAmount = selectedShareType === PaymentAmountType.FULL
            ? fullAmount
            : partialAmount;

        setFinalAmountToPay(finalAmount);
    }, [selectedShareType, fullAmount, partialAmount]);

    const isFabDisabled = useMemo(() => {

        if (moneyToNumber(finalAmountToPay) >= 0) {

            const { canPay, errorMessageIntlId } = paymentHelper.canSharePay(finalAmountToPay, amountRemaining, minAmount, maxAmount);

            if (canPay) {
                setErrorMessage(null)
                return false;
            }
            else {
                if (errorMessageIntlId) {
                    setErrorMessage(errorMessageIntlId)
                }
                return true;
            }
        }
        else {
            return true;
        }
    }, [amountRemaining, maxAmount, minAmount, finalAmountToPay]);

    /**
     * Set the splitBy number if not defined. Try to set it using the latest payment share_number. If not
     * found, set it to 2 (DEFAULT_SPLIT_BY).
     */
    useEffect(() => {

        if (!splitBy) {

            const paymentTypeInfo: SupportedPayementType | undefined = selectedLocation?.supported_payment_types?.find(payment => (
                payment.type === orderState.payment_type
            ));

            if (paymentTypeInfo) {
                const paymentShareInfos: PaymentShareInfos | undefined = paymentHelper.getSharePaymentsInfos(
                    order.payments,
                    paymentTypeInfo,
                    currency,
                );
                setSplitBy(paymentShareInfos?.share_number ?? DEFAULT_SPLIT_BY);
            }
        }

    }, [currency, order.payments, orderState.payment_type, selectedLocation?.supported_payment_types, splitBy]);

    /**
     * Redirect to the home page if the payment_type is not defined.
     */
    useEffect(() => {
        if (!orderState.payment_type) {
            history.push(`/${tableLinkId}${OrderSummaryPage}`);
        }
    }, [history, orderState.payment_type, tableLinkId]);

    /**
     * Is triggered whenever we change the selected quantity of an item.
     * Quantity can be 0, meaning that we unselected it.
     * @param items can be multiple items if the quantity of a deal is changed
     */
    const onItemQuantityChanged = (items: OrderItemToPay[]) => {

        const nSelectedItems = _.cloneDeep(selectedItems)
        items.forEach(item => {

            const foundItem = nSelectedItems.find(i => i.index === item.index);

            if (item.quantity === 0) {
                if (foundItem) {
                    nSelectedItems.splice(nSelectedItems.indexOf(foundItem), 1);
                    return;
                }
            }
            else {
                if (!foundItem) {
                    nSelectedItems.push(item);
                    return;
                }
                else {
                    // Replace the item (to update quantity)
                    nSelectedItems.splice(nSelectedItems.indexOf(foundItem), 1, item);
                }
            }
        });
        setSelectedItems(nSelectedItems);
    }

    const changeSharePaymentType = (type: PaymentAmountType) => {

        switch (type) {
            case PaymentAmountType.FULL:
                setSelectedShareType(PaymentAmountType.FULL)
                break

            case PaymentAmountType.MANUAL:
                setSelectedShareType(PaymentAmountType.MANUAL)
                break

            case PaymentAmountType.SPLIT:
                setFinalAmountToPay(numberToMoney(0, currency))
                setSelectedShareType(PaymentAmountType.SPLIT)
                break

            case PaymentAmountType.ITEMS:
                setFinalAmountToPay(numberToMoney(0, currency))
                setSelectedShareType(PaymentAmountType.ITEMS)
                break

            default:
                setSelectedShareType(PaymentAmountType.NONE)
                break
        }
    }

    const handleSplitUpdate = (plusOrMinusValue: number) => {
        setSelectedItems([]);
        setSplitBy(currentSplitBy => (currentSplitBy ?? DEFAULT_SPLIT_BY) + plusOrMinusValue);
    }

    const handleCustomAmount = (val: string, isTotal?: boolean) => {
        if (val === '') {
            setCustomAmount('')
        } else {
            const sanitizedString = val.replace(',', '.')
            const parsedValue = parseFloat(sanitizedString)

            if (!isNaN(parsedValue) && parsedValue >= 0) {
                if (isTotal) {
                    const max = moneyToNumber(amountRemaining)
                    const nTotal = parsedValue > max ? max : parsedValue
                    setFinalAmountToPay(numberToMoney(nTotal, currency))
                } else {
                    setCustomAmount(val)
                }
            }
        }
    }

    /**
     * Final click (main button or validate from tip modal)
     * @param tipChargeToAdd 
     * @returns 
     */
    const onFinalAction = (tipChargeToAdd: OrderCharge = tipCharge!) => {

        // Do not go further if the payment type is not defined.  // TODO duplicated
        if (!orderState.payment_type) {
            history.push(`/${tableLinkId}${OrderSummaryPage}`);
            return;
        }

        let amountToPay: Money = finalAmountToPay
        let paymentAmountType: PaymentAmountType = PaymentAmountType.MANUAL

        if (selectedItems.length > 0) {
            paymentAmountType = PaymentAmountType.ITEMS
        } else if (splitBy && splitBy > 1) {
            paymentAmountType = PaymentAmountType.SPLIT
        }
        if (finalAmountToPay && selectedLocation && tipChargeToAdd && moneyToNumber(tipChargeToAdd.price) > 0) {
            amountToPay = addMoney(finalAmountToPay, tipChargeToAdd.price)
            log.debug(`Add ${tipChargeToAdd.price} to aumount to pay ${finalAmountToPay} as tip for a total of ${amountToPay}`)
        }

        sendOrPayOrder(
            dispatch,
            order,
            data.user_authentication_state.user?.uid ?? "",
            orderState.payment_type,
            amountToPay,
            undefined,
            selectedItems,
            paymentAmountType,
            tipChargeToAdd,
            selectedLocation?.enable_share_payment,
            localLoyaltyUsePoints,
        )
    }

    return (
        <Box
            minHeight='100%'
            display='flex'
            flexDirection='column'
            bgcolor={theme.palette.background.default}
        >
            {tip && askForTip && finalAmountToPay &&
                <TipModal
                    open={openTipModal}
                    onClose={() => setOpenTipModal(false)}
                    tip={tip}
                    onFinalAction={(charge?: OrderCharge) => onFinalAction(charge)}
                    currency={currency}
                    paymentAmount={finalAmountToPay}
                    charge={tipCharge}
                    onChargeChange={setTipCharge}
                />
            }

            <OrderHeader
                titleId={intl.formatMessage({ id: 'orders.sharePayment.my_selection' })}
                readOnly
                closeIcon
                backTwice
            />

            <SharePaymentSelection
                separatedItems={separatedItems}
                selectedItems={selectedItems}
                onItemQuantityChanged={onItemQuantityChanged}
                setSelectedItems={setSelectedItems}
                amountRemaining={amountRemaining}
                selectedShareType={selectedShareType}
                setSelectedShareType={changeSharePaymentType}
                customAmount={customAmount}
                handleCustomAmount={handleCustomAmount}
                remainingRatio={remainingRatio}
                splitBy={splitBy}
                handleSplitUpdate={handleSplitUpdate}
                userId={userId}
            />

            <Box flex={1} />

            <ShareItemsPageBottom
                total={moneyToNumber(finalAmountToPay)}
                errorMessage={errorMessage}
                minAmount={minAmount}
                maxAmount={maxAmount}
                isFabDisabled={isFabDisabled}
                onClick={() => askForTip ? setOpenTipModal(true) : onFinalAction()}
                order={order}
            />
        </Box >
    )
}

export default SharePaymentPage;
