import { Box, Button, Typography, useTheme } from '@mui/material'
import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { DeviceContext } from '../../App'
import { defaultMyLemonadeSummaryBottomShadow } from '../../config/theme'
import { OrderInBase } from '../../my-lemonade-library/model/Order'
import { MoneyToStringWithSymbol, numberToMoney } from '../../my-lemonade-library/src/common/models/Money'
import LoyaltySummary from '../../orders/components/LoyaltySummary'
import { RootState, useTypedSelector } from '../../redux/root-reducer'

interface ShareItemsPageBottomProps {
    total: number;
    errorMessage: string | null;
    minAmount: string | undefined;
    maxAmount: string | undefined;
    isFabDisabled: boolean;
    order: OrderInBase;
    onClick: () => void;
}

const ShareItemsPageBottom = (props: ShareItemsPageBottomProps) => {

    const {
        total,
        errorMessage,
        minAmount,
        maxAmount,
        isFabDisabled,
        order,
        onClick
    } = props

    const { selectedCatalog } = useTypedSelector((state: RootState) => state.locations)

    const { mobile_device } = useContext(DeviceContext)

    const theme = useTheme()
    const intl = useIntl()

    if (!selectedCatalog) {
        return null
    }

    return (

        <Box
            p={2}
            mt={2}
            display='flex'
            flexDirection='column'
            alignItems='center'
            style={{ position: 'sticky', bottom: 0 }}
            bgcolor={theme.palette.background.paper}
            boxShadow={mobile_device ? defaultMyLemonadeSummaryBottomShadow : "none"}
            gap={1}
        >

            <Box px={1} width={1}>
                <LoyaltySummary
                    selectedOrder={order}
                    localLoyaltyEnabled
                />
            </Box>

            <Box
                width={1}
                px={1}
                display='flex'
                justifyContent='space-between'
            >
                <Typography variant='h5'>
                    {intl.formatMessage({ id: 'orders.sharePayment.totalToPay' })}
                </Typography>
                <Typography variant='h5' data-test="share-items.total-to-pay">
                    {MoneyToStringWithSymbol(numberToMoney(total, selectedCatalog.currency))}
                </Typography>
            </Box>

            <Box >
                {errorMessage &&
                    <Typography variant='caption' color='primary' align='center'>
                        {intl.formatMessage(
                            { id: errorMessage },
                            {
                                minAmount: minAmount ? MoneyToStringWithSymbol(minAmount) : "",
                                maxAmount: maxAmount ? MoneyToStringWithSymbol(maxAmount) : "",
                            }
                        )}
                    </Typography>
                }
            </Box>

            <Button
                disabled={isFabDisabled}
                variant="contained"
                data-test="summary_validateOrder"
                color="primary"
                disableElevation
                onClick={onClick}
                style={{
                    textTransform: "none",
                    width: '100%',
                    padding: theme.spacing(1.5, 2)
                }}
            >
                <Typography variant='h5'>
                    {intl.formatMessage({ id: 'Summary.sharePayment.pay' })}
                </Typography>
            </Button>

        </Box>
    )
}

export default ShareItemsPageBottom