import _ from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import { applyPricingEffect } from "../../../functions/Helpers";
import { Catalog, DEFAULT_LANGUAGE, getTimezoneName, PricingEffect } from "../../../model/Catalog";
import { SupportedServiceType } from "../../../model/Location";
import { Order } from "../../../model/Order";
import { SignInProviders } from "../../authentications/models/BaseUser";
import { DEFAULT_CURRENCY, Money, moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from "../../common/models/Money";
import { log } from "../../common/services/LogService";
import OrderContributor from "../../orders/models/OrderContributor";
import { RestrictionInvalidityReason } from "../../restrictions/model/RestrictionInvalidityReason";
import { CheckTimeMode, getRestrictionsArray, isMatchingTemporalRestrictionWithReason } from "../../restrictions/services/RestrictionsService";
import L from "../../translations/locales/i18n-node";
import { Locales } from "../../translations/locales/i18n-types";
import { locales } from "../../translations/locales/i18n-util";
import { getDefaultOrderDiscount } from "../configs/variables";
import { Discount } from "../models/Discount";
import DiscountConfig from "../models/DiscountConfig";
import { DiscountInvalidity } from "../models/DiscountInvalidity";
import { DiscountForProvider } from "../models/DiscountsForProvider";
import { DiscountType, OrderDiscount } from "../models/OrderDiscount";
import { OrderDiscountError } from "../models/OrderDiscountError";

interface DiscountAndContributor {
    discount: Discount,
    contributorId: string
}

class DiscountService {

    getAutoApplyDiscountInCatalog(catalogDiscounts: Discount[]): Discount[] {
        const autoApplyDiscountsList = catalogDiscounts.filter(discount => discount.auto_apply);
        return autoApplyDiscountsList;
    };

    /**
     * retrieves discounts in order that matches discounts in catalog
     * Check if it's a valid discount
     * @param orderDiscount 
     * @param catalogDiscounts 
     * @returns a Discount, or throw an error
     */
    retrieveCatalogDiscountWithOrderDiscount(orderDiscount: OrderDiscount, catalogDiscounts: Discount[]): Discount {
        const discount = catalogDiscounts.find(discount => discount.ref === orderDiscount.ref)
        if (discount) {
            return discount;
        } else {
            throw OrderDiscountError.DISCOUNT_NOT_FOUND;
        }
    };

    /** 
     * check if there are defined domains or providers discounts, 
     * and an order contributor eligibility for it 
     * //TODO: TEST
     * @param contributor 
     * @param authorizedDomains 
     * @param authorizedProviders 
     * @returns 
     */
    checkContributorEligibility(
        contributor: OrderContributor,
        authorizedDomains: string[] | undefined,
        authorizedProviders: SignInProviders[] | undefined,
        emailVerifiedRequired: boolean | undefined
    ): boolean | undefined {

        let isContributorEligible: boolean = true;

        if (authorizedDomains) {
            isContributorEligible = false;

            if (contributor?.email) {
                isContributorEligible = authorizedDomains.find(domain => contributor.email?.includes(domain)) !== undefined;
            }

            if (!isContributorEligible) {
                // Return directly, no other check
                return isContributorEligible;
            }
        }

        if (emailVerifiedRequired && !contributor.email_verified) {
            return false;
        }

        // Second check: providers
        if (authorizedProviders) {
            isContributorEligible = false;
            if (contributor?.private_refs) {
                for (let signinProvider in contributor.private_refs) {
                    if (authorizedProviders.includes(signinProvider as SignInProviders)) {
                        isContributorEligible = true;
                        break;
                    }
                }
            }
            if (!isContributorEligible && contributor?.sign_in_provider && authorizedProviders.includes(contributor.sign_in_provider)) {
                isContributorEligible = true;
            }
        }
        return isContributorEligible;
    };


    /**
     * Control if a discount has restriction, like authorized domains email or providers
     * also check if a max discount per order or per customer
     * @param order 
     * @param userId 
     * @param discount 
     * @param catalogTimeZone 
     * @param discountConfig 
     * @param catalogId 
     * @param disableExpectedTimeResetForEatin 
     * @param alreadyCheckedOrderDiscounts 
     * @returns boolean
     */
    checkDiscountRestrictions(
        now: DateTime,
        order: Order,
        userId: string,
        discount: Discount,
        catalogTimeZone: string,
        discountConfig: DiscountConfig,
        catalogId: string,
        disableExpectedTimeResetForEatin: boolean,
        tableAreaRef: string | undefined,
        alreadyCheckedOrderDiscounts?: OrderDiscount[] | undefined
    ): boolean {

        const checkedRestrictionsResult = this.checkDiscountRestrictionsReason(
            now,
            order,
            userId,
            discount,
            catalogTimeZone,
            discountConfig,
            catalogId,
            disableExpectedTimeResetForEatin,
            tableAreaRef,
            alreadyCheckedOrderDiscounts,
        );
        return checkedRestrictionsResult.result;
    }

    getDiscountInvalidityReasonFromRestrictionInvalidity(restrictionInvalidityReason: RestrictionInvalidityReason) {
        switch (restrictionInvalidityReason) {
            case RestrictionInvalidityReason.RESTRICTION_END_DATE_NOT_RESPECTED:
                return DiscountInvalidity.DISCOUNT_END_DATE_NOT_RESPECTED;
            case RestrictionInvalidityReason.RESTRICTION_START_DATE_NOT_RESPECTED:
                return DiscountInvalidity.DISCOUNT_START_DATE_NOT_RESPECTED;
            case RestrictionInvalidityReason.RESTRICTION_END_TIME_NOT_RESPECTED:
                return DiscountInvalidity.DISCOUNT_END_TIME_NOT_RESPECTED;
            case RestrictionInvalidityReason.RESTRICTION_START_TIME_NOT_RESPECTED:
                return DiscountInvalidity.DISCOUNT_START_TIME_NOT_RESPECTED;
            case RestrictionInvalidityReason.RESTRICTION_MIN_ORDER_AMOUNT_NOT_REACHED:
                return DiscountInvalidity.DISCOUNT_MIN_ORDER_AMOUNT_NOT_REACHED;
            case RestrictionInvalidityReason.RESTRICTION_DOW_NOT_RESPECTED:
                return DiscountInvalidity.DISCOUNT_DOW_NOT_RESPECTED;
            case RestrictionInvalidityReason.RESTRICTION_SERVICE_TYPE_NOT_RESPECTED:
                return DiscountInvalidity.DISCOUNT_SERVICE_TYPE_NOT_RESPECTED;
            case RestrictionInvalidityReason.RESTRICTION_TABLE_AREA_NOT_RESPECTED:
                return DiscountInvalidity.DISCOUNT_TABLE_AREA_NOT_RESPECTED;
            default:
                return DiscountInvalidity.DISCOUNT_RESTRICTION_NOT_MET;
        }
    }

    /**
    * Used to return the reason of the discount invalidity
    * @param discount
    * @param invalidityReason
    */
    checkDiscountRestrictionsReason(
        now: DateTime,
        order: Order,
        userId: string,
        discount: Discount,
        catalogTimeZone: string,
        discountConfig: DiscountConfig,
        catalogId: string,
        disableExpectedTimeResetForEatin: boolean,
        tableAreaRef: string | undefined,
        alreadyCheckedOrderDiscounts?: OrderDiscount[] | undefined,
    ): { result: boolean, reason: DiscountInvalidity | undefined } {

        const contributor = order.contributors && order.contributors[userId]
            ? order.contributors[userId]
            : null;

        if (!contributor) {
            return {
                result: false,
                reason: DiscountInvalidity.CONTRIBUTOR_NOT_FOUND
            };
        }

        const discountConfigMaxPerOrder = discountConfig.max_per_order ?? 1;
        const discountConfigMaxPerCustomer = discountConfig.max_per_customer_in_one_order
            ?? discountConfig["max_per_customer" as keyof DiscountConfig] // Legacy, this field has been renamed since
            ?? 1;

        if (alreadyCheckedOrderDiscounts) {
            if (discountConfigMaxPerCustomer !== -1) {
                if (alreadyCheckedOrderDiscounts?.filter((discount) => discount.user_id === userId).length >= discountConfigMaxPerCustomer) {
                    return {
                        result: false,
                        reason: DiscountInvalidity.DISCOUNT_MAX_PER_CUSTOMER_USAGE_LIMIT
                    };
                }
            }
            if (discountConfigMaxPerOrder !== -1) {
                if (alreadyCheckedOrderDiscounts.length >= discountConfigMaxPerOrder) {
                    return {
                        result: false,
                        reason: DiscountInvalidity.DISCOUNT_MAX_PER_ORDER_USAGE_LIMIT
                    };
                }
            }
        }
        //clean + verifier 2x même utilisateur;

        const maxPerCustomer = discount.usage_restriction?.max_per_customer ?? 1;

        // we check the previously checked discounts
        if (alreadyCheckedOrderDiscounts?.length) {
            const maxPerOrder = discount.usage_restriction?.max_per_order ?? 1;
            const checkOfPreviousDiscounts = alreadyCheckedOrderDiscounts.filter((checkedDiscount) => checkedDiscount.ref === discount.ref);

            if (maxPerOrder !== -1) { // maxPerOrder = -1 => infinite number of discount with the same ref
                if (checkOfPreviousDiscounts.length >= maxPerOrder)
                    return {
                        result: false,
                        reason: DiscountInvalidity.DISCOUNT_MAX_PER_ORDER_USAGE_LIMIT
                    }
            }
            const checkOfPreviousDiscountsPerCustomerForCurrentOrder = checkOfPreviousDiscounts.filter((checkedDiscount) => checkedDiscount.user_id === userId)
            // Always one per customer in an order
            if (checkOfPreviousDiscountsPerCustomerForCurrentOrder.length >= 1) {
                return {
                    result: false,
                    reason: DiscountInvalidity.DISCOUNT_MAX_PER_CUSTOMER_USAGE_LIMIT
                };
            }
        }

        // We check the max per customer for ALL orders by this customer
        if (maxPerCustomer !== -1) {
            if (contributor?.used_discounts?.[catalogId]?.[discount.ref] && contributor.used_discounts[catalogId][discount.ref] !== null) {
                const usedCount = contributor?.used_discounts?.[catalogId]?.[discount.ref].used_count;
                if (usedCount >= maxPerCustomer) {
                    return {
                        result: false,
                        reason: DiscountInvalidity.DISCOUNT_MAX_PER_CUSTOMER_USAGE_LIMIT
                    };
                }
            }
        }

        if (discount.usage_restriction) {

            if (discount.usage_restriction.emails_domain_allowed || discount.usage_restriction.authentication_providers || discount.usage_restriction.email_verified_required) {
                if (contributor) {
                    const isContributorEligible = this.checkContributorEligibility(
                        contributor,
                        discount.usage_restriction.emails_domain_allowed,
                        discount.usage_restriction.authentication_providers,
                        discount.usage_restriction.email_verified_required
                    );
                    if (!isContributorEligible) return {
                        result: false,
                        reason: DiscountInvalidity.DISCOUNT_USAGE_RESTRICTION_NOT_MET
                    };
                } else return {
                    result: false,
                    reason: DiscountInvalidity.DISCOUNT_USAGE_RESTRICTION_NOT_MET
                };
            }
        }

        const restrictions = getRestrictionsArray(discount.restrictions);

        if (restrictions) {
            const dateMoment = moment(now.toJSDate()).tz(catalogTimeZone);
            let restrictionNotMatchingReason: RestrictionInvalidityReason | undefined = undefined;
            for (const restriction of restrictions) {

                const foundMatchingRestriction = isMatchingTemporalRestrictionWithReason(
                    dateMoment,
                    catalogTimeZone,
                    restriction,
                    order.service_type,
                    CheckTimeMode.FULL,
                    order.subtotal,
                    tableAreaRef
                );

                if (foundMatchingRestriction.isMatching) {
                    return {
                        result: true,
                        reason: undefined
                    };
                } else {
                    if (!restrictionNotMatchingReason) {
                        restrictionNotMatchingReason = foundMatchingRestriction.reason
                    }
                }
            }
            return {
                result: false,
                reason: restrictionNotMatchingReason
                    ? this.getDiscountInvalidityReasonFromRestrictionInvalidity(restrictionNotMatchingReason)
                    : DiscountInvalidity.DISCOUNT_RESTRICTIONS_NOT_RESPECTED
            }
        }
        else {
            return {
                result: true,
                reason: undefined
            };
        }
    };

    getOrderDiscountsTotal(order_discounts: OrderDiscount[], total: number, catalog: Catalog) {
        if (!(Array.isArray(order_discounts) && order_discounts.length)) {
            return 0
        }

        const catalog_discounts = catalog.data.discounts;

        if (!(catalog_discounts && catalog_discounts.length)) {
            return 0;
        }

        let total_discount = 0

        order_discounts.forEach(discount => {
            /**
             * Loyalty discount is a special case and does not need to be computed
             */
            if (discount.type === DiscountType.LOYALTY) {
                total_discount += moneyToNumber(discount.price_off);
                return
            }

            /**
             * Already computed discounts, no need to recompute
             */
            if (discount.update_id) {
                total_discount += moneyToNumber(discount.price_off);
                return
            }

            /**
             * Compute the discount based on order total
             */
            try {
                const catalog_discount = this.retrieveCatalogDiscountWithOrderDiscount(discount, catalog_discounts);

                const discount_value: number = this.computeOrderDiscountPrice(total, catalog_discount.pricing_effect, catalog_discount.pricing_value);

                total_discount += discount_value;
            } catch {
                /**
                 * Can throw an error if discount is not found in catalog
                 */
            }
        })

        return total_discount
    }

    /**
     * Global function to apply discount to an order
     * add OrderDiscount to the order passed and return the total discount to apply to an Order total
     * @param order 
     * @param catalog 
     * @param now 
     * @returns 
     */
    applyDiscountsToOrder(
        now: DateTime,
        order: Order,
        catalog: Catalog,
        finalPrice: number,
        disableExpectedTimeResetForEatin: boolean,
        tableAreaRef: string | undefined
    ): number {

        const catalogDiscounts = catalog.data.discounts;
        if (!catalogDiscounts || catalogDiscounts.length === 0) {
            return 0;
        }

        const discountConfig: DiscountConfig = catalog.discounts ?? {};
        const catalogTimeZone = getTimezoneName(catalog);

        const newOrderDiscounts: OrderDiscount[] = [];
        let totalDiscount: number = 0;

        // Checking the validity of current order discounts
        order.discounts?.forEach((orderDiscount) => {

            if (orderDiscount.type === DiscountType.LOYALTY) {
                newOrderDiscounts.push(orderDiscount);
                return;
            }

            // Do not recompute discounts with update_id (i.e. already sent to the POS)
            if (orderDiscount.update_id) {
                newOrderDiscounts.push(orderDiscount);
                totalDiscount += moneyToNumber(orderDiscount.price_off);
                return;
            }

            const userId = orderDiscount.user_id;

            if (!userId) {
                return;
            }

            const discountToApply = this.retrieveCatalogDiscountWithOrderDiscount(orderDiscount, catalogDiscounts);

            const isDiscountAvailable = this.checkDiscountRestrictions(
                now,
                order,
                userId,
                discountToApply,
                catalogTimeZone,
                discountConfig,
                catalog.id!,
                disableExpectedTimeResetForEatin,
                tableAreaRef,
                newOrderDiscounts
            );

            if (!isDiscountAvailable) {
                return;
            }

            const { discountPriceOff, discountToAdd } = this.getDiscountToApply(discountToApply, catalog.currency, finalPrice, userId);
            newOrderDiscounts.push(discountToAdd);
            totalDiscount += discountPriceOff;
        });

        // TODO: check only if not already a discount registered
        const discountsAutoApply = this.getAutoApplyDiscountInCatalog(catalogDiscounts);
        if (
            order.contributors
            && Object.keys(order.contributors).length > 0
            && discountsAutoApply.length > 0
            // To prevent an auto discount to come back automatically after it's been removed by the user
            && !Object.values(order.contributors).some(contributor => contributor.disable_auto_discount)
        ) {
            const contributors = Object.keys(order.contributors);

            // Search for auto apply discounts which can be used (not already used by customers, etc.)
            const discountsAndContributors: DiscountAndContributor[] = []
            discountsAutoApply.forEach((discountAutoApply) => {

                //console.log(`Reduce: ${currentIndex}`);
                contributors.forEach(contributor => {

                    // TODO: update
                    const isAvailable = this.checkDiscountRestrictions(
                        now,
                        order,
                        contributor,
                        discountAutoApply,
                        catalogTimeZone,
                        discountConfig,
                        catalog.id!,
                        disableExpectedTimeResetForEatin,
                        tableAreaRef,
                        newOrderDiscounts
                    )

                    if (isAvailable) {
                        const dAndc: DiscountAndContributor = {
                            discount: discountAutoApply,
                            contributorId: contributor
                        }
                        discountsAndContributors.push(dAndc)
                    }
                })
            })

            // Sort to find the most interesting discount
            discountsAndContributors.sort((a, b) =>
                this.sortDiscountsByMostInteresting(a, b, catalog.currency, finalPrice)
            )

            // Apply the most interesting discount
            if (discountsAndContributors && discountsAndContributors.length > 0 && discountsAndContributors[0]) {
                const { discountPriceOff, discountToAdd } = this.getDiscountToApply(discountsAndContributors[0].discount, catalog.currency, finalPrice, discountsAndContributors[0].contributorId);
                newOrderDiscounts.push(discountToAdd);
                totalDiscount += discountPriceOff;
            }
        }

        order.discounts = newOrderDiscounts;
        return totalDiscount;
    }

    getDiscountToApply(discount: Discount, currency: string, finalPrice: number, userId: string): { discountToAdd: OrderDiscount; discountPriceOff: number; } {

        const discountToAdd: OrderDiscount = getDefaultOrderDiscount(discount.ref, discount.name, currency, userId);
        const discountPriceOff: number = this.computeOrderDiscountPrice(finalPrice, discount.pricing_effect, discount.pricing_value);

        if (!_.isNil(discountPriceOff)) {
            discountToAdd.price_off = numberToMoney(discountPriceOff, currency);
        }
        return { discountToAdd, discountPriceOff };
    };

    getDiscountPricingValueString(discount: Discount): string {
        if (discount.pricing_effect === PricingEffect.EFFECT_PRICE_OFF) {
            if (discount.pricing_value) {
                const pricingValue = discount.pricing_value as string;
                return MoneyToStringWithSymbol(pricingValue);
            } else {
                log.error(`Discount doesn't have pricing value`);
                return "";
            }
        } else if (discount.pricing_effect === PricingEffect.EFFECT_PERCENTAGE_OFF) {
            if (discount.pricing_value) {
                return `${discount.pricing_value} %`
            } else {
                log.error(`Discount doesn't have pricing value`);
                return "";
            }

        } else {
            log.error(`pricing effect ${discount.pricing_effect} should not be affected to a discount`);
            return "";
        }
    }

    getDiscountPricingValue(discount: Discount, includeMinus: boolean, subtotal?: number, currency?: string): string | null {
        if (discount.pricing_effect === PricingEffect.EFFECT_PRICE_OFF) {

            if (_.isNil(discount.pricing_value)) {
                log.error(`Discount doesn't have pricing value`);
                return null;
            }

            let discountPricingValue = discount.pricing_value;

            if (discountPricingValue === "0") {
                discountPricingValue = 0;
            }

            const pricingValue = (typeof discountPricingValue === "string")
                ? discountPricingValue
                : numberToMoney(discountPricingValue, currency ?? DEFAULT_CURRENCY);

            if (includeMinus) {
                return `- ${MoneyToStringWithSymbol(pricingValue)}`;
            }

            return MoneyToStringWithSymbol(pricingValue);
        }

        if (discount.pricing_effect === PricingEffect.EFFECT_PERCENTAGE_OFF) {
            if (_.isNil(discount.pricing_value)) {
                log.error(`Discount doesn't have pricing value`);

                return null;
            }

            const pricingValue = discount.pricing_value as number;

            if (subtotal && currency) {
                const discountAsNumber: number = (Math.round(subtotal * pricingValue) / 100)
                const discountAsMoney: Money = numberToMoney(discountAsNumber, currency)

                if (includeMinus) {
                    return `- ${MoneyToStringWithSymbol(discountAsMoney)}`;
                }

                return `${MoneyToStringWithSymbol(discountAsMoney)}`;
            }

            if (includeMinus) {
                return `- ${pricingValue}%`;
            }

            return `${pricingValue}%`;
        }

        log.error(`pricing effect ${discount.pricing_effect} should not be affected to a discount`);

        return null;
    };

    getOrderDiscountPricingValue(orderDiscount: OrderDiscount, catalog: Catalog, includeMinus: boolean, subtotal: number): string | null {
        const orderDiscountRef = orderDiscount.ref;
        const catalogDiscounts = catalog.data.discounts;

        if (orderDiscount.update_id) {
            log.debug("Discount has an update_id");
            return null;
        }

        if (orderDiscount.type === DiscountType.LOYALTY) {
            log.debug('Loyalty discount');
            return null;
        }

        if (!(catalogDiscounts && catalogDiscounts.length)) {
            log.error('No discount in this catalog');
            return null;
        }

        const foundDiscount = catalogDiscounts.find(discount => discount.ref === orderDiscountRef);

        if (!foundDiscount) {
            log.error(`Discount with ref ${orderDiscountRef} not found in catalog ${catalog.id}`)
            return null;
        }

        return this.getDiscountPricingValue(foundDiscount, includeMinus, subtotal, catalog.currency);
    };

    getOrderTotalDiscount(order: Order): number {
        let totalDiscount = 0;
        order.discounts?.forEach((orderDiscount) => {
            totalDiscount += moneyToNumber(orderDiscount.price_off);
        })
        return totalDiscount;
    };

    getDiscountDescription(discount: Discount, language: string): string {
        let selectedLocale: Locales = language as Locales;
        if (!selectedLocale || !locales.includes(selectedLocale)) {
            selectedLocale = DEFAULT_LANGUAGE as Locales
        }
        const discountPricingValue = this.getDiscountPricingValueString(discount);
        const discountRestrictions = getRestrictionsArray(discount.restrictions);

        // TODO: reuse function from clement dealing with union ?
        let discountServiceTypes: SupportedServiceType[] | undefined = undefined;
        if (discountRestrictions) {
            for (let discountRestriction of discountRestrictions) {
                if (!discountRestriction.service_types) {
                    discountServiceTypes = undefined;
                    break;
                } else {
                    if (!discountServiceTypes) {
                        discountServiceTypes = []
                    }
                    discountRestriction.service_types.forEach((serviceType) => {
                        if (!discountServiceTypes!.includes(serviceType)) {
                            discountServiceTypes!.push(serviceType)
                        }
                    });
                }
            }
        }
        const serviceTypeString = discountServiceTypes ? discountServiceTypes.map((discountServiceType) => {
            switch (discountServiceType) {
                case SupportedServiceType.EAT_IN:
                    return L[selectedLocale].discounts_service_type_eat_in();
                case SupportedServiceType.COLLECTION:
                    return L[selectedLocale].discounts_service_type_collection();
                case SupportedServiceType.DELIVERY:
                    return L[selectedLocale].discounts_service_type_delivery();
                case SupportedServiceType.VIEW:
                    return L[selectedLocale].discounts_service_type_view();
                case SupportedServiceType.CHECKOUT:
                    return L[selectedLocale].discounts_service_type_checkout();
            }

        }).join(L[selectedLocale].discounts_service_type_or_join()) : "";

        let discountUsageNumber = discount.usage_restriction?.max_per_customer ?? 1;

        // Set to 0, because i18n does not support -1 value in plural
        // See: https://github.com/ivanhofer/typesafe-i18n/tree/main/packages/runtime#plural
        if (discountUsageNumber === -1) {
            discountUsageNumber = 0
        }

        return L[selectedLocale].discounts_description({
            value: discountPricingValue,
            service_types: serviceTypeString,
            number: discountUsageNumber
        });
    }

    /**
     * @param finalPrice 
     * @param pricing_effect 
     * @param pricing_value 
     * @returns the amount (money) of the discount
     */
    computeOrderDiscountPrice(finalPrice: number, pricing_effect: PricingEffect, pricing_value: string | number | undefined) {

        let discountedPrice = applyPricingEffect(finalPrice, pricing_effect, pricing_value);
        if (_.isNil(discountedPrice)) {
            return 0;
        }
        if (discountedPrice < 0) {
            return finalPrice;
        }
        return finalPrice - discountedPrice;
    };

    isAValidDiscount(orderDiscount: OrderDiscount, catalogDiscounts: Discount[]): boolean {
        const discount = catalogDiscounts.find(catalogDiscount => catalogDiscount.ref.includes(orderDiscount.ref));
        return discount ? true : false;
    };

    sortDiscountsByMostInteresting(a: DiscountAndContributor, b: DiscountAndContributor, currency: string, finalPrice: number): number {
        const aDiscount = this.getDiscountToApply(a.discount, currency, finalPrice, a.contributorId)
        const bDiscount = this.getDiscountToApply(b.discount, currency, finalPrice, b.contributorId)

        return bDiscount.discountPriceOff - aDiscount.discountPriceOff
    }

    /**
     * // TODO: comment!
     * @param catalogDiscounts
     * @param authProviders 
     * @returns 
     */
    getDiscountAutoApplyAvailableProvider = (
        catalogDiscounts: Discount[],
        authProviders: SignInProviders[]
    ): DiscountForProvider[] => {

        const discounts: DiscountForProvider[] = []

        authProviders.forEach(provider => {

            const discountsFiltered = catalogDiscounts.filter(discount => discount.usage_restriction?.authentication_providers?.includes(provider));

            if (discountsFiltered && discountsFiltered.length > 0) {
                discounts.push({
                    auth_provider: provider,
                    discounts: discountsFiltered
                })
            }
        })

        return discounts
    }

    /**
     * Returns a value with all applicable discounts applied based on catalog rules
     * @param amount The original amount to apply discounts to
     * @param catalog The catalog containing discount rules and limitations
     * @param currency The currency of the amount (not used currently)
     * @param discounts Optional array of order discounts to apply
     * @returns The amount after applying all valid discounts
     */
    getOrderAmountMinusDiscountTotal = (amount: number, catalog: Catalog, currency: string, discounts?: OrderDiscount[]): number => {
        if (!discounts) return amount;

        const catalogDiscounts: Discount[] = catalog.data.discounts || [];
        const catalogMaxDiscountPerCustomer = catalog.discounts?.max_per_customer_in_one_order;
        const catalogMaxDiscountPerOrder = catalog.discounts?.max_per_order;

        const limitToSet = Math.max(catalogMaxDiscountPerOrder || 0, catalogMaxDiscountPerCustomer || 0);
        const orderDiscountLimited = limitToSet > 0 ? discounts.slice(0, limitToSet) : discounts;

        const matchingDiscount = catalogDiscounts.filter((od) =>
            orderDiscountLimited.some((cd: OrderDiscount) => cd.ref === od.ref)
        );

        let discountedAmount = amount;
        matchingDiscount.forEach((md: Discount) => {
            if (md.pricing_value) {
                if (md.pricing_effect === PricingEffect.EFFECT_PERCENTAGE_OFF) {
                    discountedAmount *= (1 - Number(md.pricing_value) / 100);
                }
                else if (md.pricing_effect === PricingEffect.EFFECT_PRICE_OFF) {
                    const discountValue = moneyToNumber(md.pricing_value.toString());
                    const discountPercentage = (discountValue * 100) / amount;

                    if (discountPercentage > 0 && discountPercentage < 100) {
                        discountedAmount *= (1 - discountPercentage / 100);
                    }
                }
            }
        });

        return discountedAmount;
    }
};

const discountService = new DiscountService();
export default discountService;
