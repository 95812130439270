import 'firebase/auth';
import log from "loglevel";
import { DateTime } from 'luxon';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import { Dispatch } from "redux";
import { getAuthenticationProviderName } from '../../authentication/helpers/AuthenticationHelpers';
import { auth } from "../../config/firebase";
import CheckedDeliveryZone from '../../delivery/models/CheckedDeliveryZone';
import { Catalog, DEFAULT_TIMEZONE_NAME, getTimezoneName } from '../../my-lemonade-library/model/Catalog';
import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import { Location, SupportedServiceType, Table } from '../../my-lemonade-library/model/Location';
import { Order, OrderCharge, OrderChargeType, OrderStatus } from "../../my-lemonade-library/model/Order";
import { OrderError } from '../../my-lemonade-library/model/OrderError';
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import { addMoney, Money, moneyToNumber, numberToMoney, substractMoney } from "../../my-lemonade-library/src/common/models/Money";
import { AutoId } from "../../my-lemonade-library/src/common/services/AutoId";
import { Connector } from '../../my-lemonade-library/src/connectors/models/Connector';
import { Discount } from '../../my-lemonade-library/src/discounts/models/Discount';
import { DiscountType, OrderDiscount } from '../../my-lemonade-library/src/discounts/models/OrderDiscount';
import LocationOrdersConfig, { DEFAULT_DELAY_BEFORE_REFRESHING_ORDER_AGAIN, WebappTableOrders } from '../../my-lemonade-library/src/orders/models/LocationOrdersConfig';
import { COLLECTION_DATE_FORMAT } from '../../my-lemonade-library/src/orders/services/CollectionService';
import { getCurrentRestriction, isMinAmountReached } from '../../my-lemonade-library/src/orders/services/OrderPricing';
import { Restriction } from '../../my-lemonade-library/src/restrictions/model/Restriction';
import { TIME_FORMAT } from '../../my-lemonade-library/src/restrictions/services/RestrictionsService';
import { InitOrdersState } from '../redux/models/InitOrdersState';
import { default as orderActions } from "../redux/OrderActions";
import { isLocalLoyaltyOnly } from './LoyaltyHelpers';

export const addDeliveryZoneaAndCharge = (
    name: string,
    price: Money,
    dispatch: Dispatch,
    catalog: CatalogExtended,
    zone: CheckedDeliveryZone,
    location: Location,
    table: Table,
) => {

    const charge: OrderCharge = {
        type: OrderChargeType.DELIVERY,
        name: name,
        ref: AutoId.newId(name),
        price: price
    }

    // TODO: group actions
    dispatch(orderActions.setDeliveryZone(zone));

    // TODO: remove delay because it's already in the zone
    dispatch(orderActions.setCharge(charge, catalog, location, table));
}

export const getLoyaltyChoiceForOrderAndCustomer = (
    order: Order,
    userId: string,
    enableSharePayment: boolean | undefined,
    localLoyaltyUsePoints: boolean | undefined,
): boolean => {

    if (isLocalLoyaltyOnly(order, enableSharePayment)) {
        return Boolean(localLoyaltyUsePoints);
    }

    const orderContributors = order?.contributors;
    if (orderContributors) {
        const user = orderContributors[userId];
        if (user && user.use_points !== undefined && user.use_points !== null) {
            return user.use_points;
        } else return true
    }
    return true;
}

/**
 * Load link content such as qrcode or invoice
 * @param link 
 * @param setLinkData 
 */
export const loadOrderLinkData = async (link: string, setLinkData: (linkData: string) => void,) => {
    try {

        const token = await auth.currentUser?.getIdToken();
        const response = await fetch(link, {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` }
        });
        const blob = await response.blob();
        // TODO: window.URL.createObjectURL(blob) ?
        var reader = new FileReader();
        reader.onload = function () {
            if (this.result) {
                log.debug("Link data decoded")
                setLinkData(this.result as string)
            } else {
                log.error(`Error reading order link data with url ${link}`);
            }
        }; // <--- `this.result` contains a base64 data URI
        reader.onerror = () => {
            log.error(`Error reading order link data with url ${link}`);
        }
        reader.readAsDataURL(blob);
    } catch (error) {
        log.error(`Error reading order link data with url ${link}`);
    }
}

export const getNumberOfContributors = (contributors: { [key: string]: Customer } | undefined): number => {
    if (contributors) {
        return Object.keys(contributors).length;
    }
    return 0;
}

export const getDateTimeInitialValues = (catalog: Catalog | undefined, orderExpectedTime: Date | undefined, allowedDays: moment.Moment[]): [string, string] => {

    if (orderExpectedTime && catalog) {

        const dateTime = DateTime.fromJSDate(orderExpectedTime, { zone: getTimezoneName(catalog) });
        return [dateTime.toISODate(), dateTime.toFormat(TIME_FORMAT)];
    } else if (allowedDays && allowedDays.length) {
        return [allowedDays[0].format(COLLECTION_DATE_FORMAT), ""];
    } else {
        const today = DateTime.now().setZone(catalog ? getTimezoneName(catalog) : DEFAULT_TIMEZONE_NAME);
        const todayFormat = today.toISODate()
        return [todayFormat, ""];
    }
}

/**
 * Tell if an order is DRAFT or PENDING_PAYMENT or WAITING_SUBMISSION
 * @param orderStatus 
 * @returns 
 */
export const isOrderStatusDWP = (orderStatus: OrderStatus): boolean => {

    return (
        orderStatus === OrderStatus.DRAFT
        || orderStatus === OrderStatus.PENDING_PAYMENT
        || orderStatus === OrderStatus.WAITING_SUBMISSION
    )
}

/**
 * Tells whether or not we have to call orderActions.createDraftOrder after resetting the order state
 * @param locationTableOrdersConfig selectedLocation?.orders?.webapp_table_orders
 * @param tableServiceType 
 * @returns 
 */
export const needsToCreateDraftAfterReset = (
    locationTableOrdersConfig: WebappTableOrders | undefined,
    tableServiceType: SupportedServiceType | undefined,
): boolean => {

    if (
        locationTableOrdersConfig
        && locationTableOrdersConfig === WebappTableOrders.FORCE_LOAD
        && tableServiceType
        && tableServiceType === SupportedServiceType.EAT_IN
    ) {
        return true;
    }
    return false;
}

/**
 * This discount, if found, will be displayed in the WelcomeAutentication and AuthenticationAdvantages forms.
 * // TODO: this function is nonsense, it's probably not relevant anymore.
 * @param catalogDiscounts 
 * @returns 
 */
export const getDiscountAutoApplyAvailableForEveryone = (
    catalogDiscounts: Discount[] | undefined,
): Discount | undefined => {

    return catalogDiscounts?.find((discount) => Boolean(
        discount.auto_apply
        && discount.usage_restriction?.authentication_providers
        && !discount.usage_restriction.emails_domain_allowed
    ));
}

export const getDiscountProvidersLabel = (
    discount: Discount,
    intl: IntlShape,
): string => {

    if (
        discount.usage_restriction?.authentication_providers
        && discount.usage_restriction.authentication_providers.length > 0
    ) {
        let discountProvidersLabel = ""
        for (const authProvider of discount.usage_restriction.authentication_providers) {
            if (!discountProvidersLabel) {
                discountProvidersLabel = getAuthenticationProviderName(authProvider);
            } else {
                discountProvidersLabel = `${discountProvidersLabel} ${intl.formatMessage({ "id": "common.or.label" })} ${getAuthenticationProviderName(authProvider)}`;
            }
        }
        return discountProvidersLabel;
    }
    return "My Lemonade";
}

export const loadingOrdersIsErrored = (initOrdersState: InitOrdersState): boolean => {

    if (
        initOrdersState.error
        && (
            initOrdersState.error === OrderError.CANNOT_LOAD_ORDER_BY_ID
            || initOrdersState.error === OrderError.CANNOT_LOAD_ORDER_BY_REF
        )
    ) {
        return true;
    }

    return false;
}

export const getMinOrderAmountIfNotReached = (
    order: Order,
    catalog: CatalogExtended,
): Money | null => {

    // Getting the restriction
    const restriction: Restriction | null = getCurrentRestriction(catalog, order, false);

    if (restriction?.min_order_amount && !isMinAmountReached(restriction, order.total)) {
        return restriction.min_order_amount;
    }

    return null;
}

export const hasToRefreshOrderFromConnector = (
    connectorConfig: Connector | undefined | null,
    ordersConfig: LocationOrdersConfig | undefined,
    lastRefreshedAt: Date | null,
    serviceType: SupportedServiceType | undefined,
): boolean => {

    let canRefreshDate = true;

    if (lastRefreshedAt) {
        const lastRefreshDateTime = DateTime.fromJSDate(lastRefreshedAt);
        const now = DateTime.now();
        const diffSeconds = now.diff(lastRefreshDateTime, "seconds").seconds;

        // Explicitly ensure we have a number
        const delaySeconds = Number(ordersConfig?.delay_before_refreshing_order_again ?? DEFAULT_DELAY_BEFORE_REFRESHING_ORDER_AGAIN);

        if (diffSeconds < delaySeconds) {
            canRefreshDate = false;
            log.debug(`Won't refresh order from connector because the last refresh was done ${diffSeconds} seconds ago`);
        }
    }

    return Boolean(
        connectorConfig
        && ordersConfig?.allow_fetch_external_table_orders
        && canRefreshDate
        && (
            serviceType === SupportedServiceType.EAT_IN ||
            serviceType === SupportedServiceType.CHECKOUT
        )
    );
}

/**
 * Takes an amount and removes the current loyalty discount from it. It can either use a "real" order discount,
 * or the amount from the estimated loyalty operation.
 * WARN: if using the discount, it must not have been sent yet (no update_id) and must be related to the current user.
 * WARN: this function does not remove the discount if the amount and the amountRemaining are equal.
 */
export const removeLoyaltyDiscountFromAmount = (
    amount: Money,
    order: Order,
    userId: string,
    currency: string,
    enableSharePayment?: boolean,
    localLoyaltyUsePoints?: boolean,
    localLoyaltyDiscountAmount?: Money,
): Money => {
    const totalAmount = moneyToNumber(amount);

    let rawDiscountAmount: Money;
    if (isLocalLoyaltyOnly(order, enableSharePayment) && localLoyaltyDiscountAmount && localLoyaltyUsePoints) {
        rawDiscountAmount = localLoyaltyDiscountAmount;
    } else {
        rawDiscountAmount = getLoyaltyDiscountAmount(order.discounts, userId, currency);
    }

    const discountAmount = moneyToNumber(rawDiscountAmount);
    const finalDiscountAmount = Math.min(totalAmount, discountAmount);
    const amountToRemove = numberToMoney(finalDiscountAmount, currency);

    return substractMoney(amount, amountToRemove);
}

/**
 * Takes an amount and adds the current loyalty discount to it.
 * WARN: the discount must not have been sent yet (no update_id) and must be related to the current user.
 * WARN: this function does not remove the discount if the amount and the amountRemaining are equal.
 * @param amount 
 * @param amountRemaining 
 * @param discounts 
 * @returns 
 */
export const addLoyaltyDiscountToAmount = (amount: Money, discounts: OrderDiscount[] | undefined, userId: string, currency: string): Money => {
    return addMoney(amount, getLoyaltyDiscountAmount(discounts, userId, currency));
}

/**
 * Calculate the total amount of the current loyalty discounts
 * WARN: the discount must not have been sent yet (no update_id) and must be related to the current user.
 * WARN: this function does not remove the discount if the amount and the amountRemaining are equal.
 * @param discounts 
 * @returns 
 */
export const getLoyaltyDiscountAmount = (discounts: OrderDiscount[] | undefined, userId: string, currency: string): Money => {

    let discountAmount: Money = numberToMoney(0, currency);

    discounts?.forEach((d) => {

        if (
            d.type === DiscountType.LOYALTY
            && d.user_id === userId
            && !d.update_id
        ) {
            discountAmount = addMoney(discountAmount, d.price_off);
        }
    });

    return discountAmount;
}

export const isUserMasterOfTheOrder = (orderMasterUserUid: string | undefined, userId: string | undefined): boolean => {

    return Boolean(orderMasterUserUid && userId && orderMasterUserUid === userId);
}
